import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as categoriesEffects from './+state/categories/categories.effects';
import * as photosEffects from './+state/photos/photos.effects';
import { GeoPhotoCategoryFacade, categoriesFeature } from './+state/categories';
import { GeoPhotoFacade, photosFeature } from './+state/photos';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(categoriesFeature),
		provideState(photosFeature),
		provideEffects([categoriesEffects, photosEffects]),
		GeoPhotoCategoryFacade,
		GeoPhotoFacade
	]
})
export class GeoPhotosDataAccessModule {}

import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	UserRightsMessageComponent,
	YunoAdminNavbarButtonsComponent,
	YunoAdminSaveButtonComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { App, Config } from '@yuno/api/interface';

import { InternalViewService } from '../internal-view/internal-view.service';
import { AppConfigurationAtlasSettingsComponent } from '../settings/atlas-settings/atlas-settings.component';
import { AppConfigurationProjectSettingsComponent } from '../settings/project-location-settings/project-location-settings.component';
import { AppProjectTitleSettingsComponent } from '../settings/project-title-settings/project-title-settings.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		YunoCardModule,
		YunoAdminNavbarButtonsComponent,
		AppConfigurationAtlasSettingsComponent,
		AppProjectTitleSettingsComponent,
		AppConfigurationProjectSettingsComponent,
		UserRightsMessageComponent,
		YunoAdminSaveButtonComponent
	],
	providers: [
		InternalViewService,
		{ provide: DEFAULT_DIALOG_CONFIG, useValue: { hasBackdrop: true } }
	],
	selector: 'yuno-admin-app-project-configuration',
	templateUrl: './project-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppProjectConfigurationComponent
	extends AppDataComponent
	implements OnInit, OnDestroy
{
	readonly service = inject(InternalViewService);
	private readonly appFacade = inject(AppFacade);
	private readonly destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.onChanges();

		const app = this.appFacade.$app();
		const config = this.appFacade.$config();

		if (!app || !config) {
			return;
		}

		this.service.appForm.patchValue(app);
		// @ts-ignore partial language error
		this.service.configForm.patchValue(config);

		this.parseUrls();

		!this.isMinimalEditor && this.disableForms();
	}

	onChanges(): void {
		this.service.appForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => this.appFacade.updateApp(this.service.appForm.getRawValue() as App));

		this.service.configForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => {
				this.appFacade.updateSettings(this.service.configForm.getRawValue() as Config);
			});
	}

	disableForms(): void {
		this.service.appForm.disable();
		this.service.configForm.disable();
	}

	parseUrls(): void {
		const urls: string[] = this.route.snapshot.data['app'].url;
		for (const url of urls) {
			this.service.addUrl(url);
		}
	}

	onSave() {
		this.appFacade.saveSettings();
	}

	ngOnDestroy() {
		const classes = ['rounded', 'squared'];
		document.body.classList.remove(...classes);
	}
}

@if (data$ | async; as data) {
	@if (data.customStates) {
		<yuno-admin-custom-states-editor></yuno-admin-custom-states-editor>
	}
	<h1 class="font-bold">Panoramas</h1>
	<yuno-admin-table
		[pagination]="true"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [25, 50, 100],
			hidePageSize: false
		}"
		[selectable]="true"
		[filterable]="true"
		[data]="data.panoramas"
		[selectData]="data.changes"
		[columns]="tableColumns"
		(selected)="onSelect($event)"
		(selectAll)="onSelectAll($event)"
		[buttons]="tableButtons"
		[buttonEllipsis]="true"
		(clicked)="onClickEventButton($event)">
		<ng-container searchButtonsLeft>
			@if (copyEventData) {
				<button
					yuno-admin-button
					color="secondary"
					[disabled]="!copyEventData"
					(click)="clearClipboard()">
					Clear clipboard
				</button>
			}
		</ng-container>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	@if (eventButtonsData(row); as eventData) {
		<button
			yuno-admin-button-table
			[disabled]="!eventData.enabled"
			(click)="onClickEventButton(row)">
			States ({{ eventData.eventCount }})
		</button>
		<button
			yuno-admin-button-table
			color="secondary"
			[disabled]="!eventData.enabled || !eventData.eventCount"
			(click)="copyEvents(row)">
			Copy Events
		</button>
		@if (copyEventData) {
			<button
				yuno-admin-button-table
				color="secondary"
				[disabled]="!eventData.enabled"
				(click)="pasteEvents(row)">
				Paste Events
			</button>
		}
	}
</ng-template>

import { Routes } from '@angular/router';

import { DatasetListviewFencesComponent } from '../listviews/fences/fences.component';
import { DatasetListviewGeoPhotosComponent } from '../listviews/geophotos/geo-photos.component';
import { DatasetListviewLayersComponent } from '../listviews/layers/layers.component';
import { DatasetListviewMarkerCategoriesComponent } from '../listviews/marker-categories/marker-categories.component';
import { DatasetListviewMarkersComponent } from '../listviews/markers/markers.component';
import { DatasetListviewObjectsComponent } from '../listviews/objects/objects.component';
import { DatasetListviewPanoramasComponent } from '../listviews/panoramas/panoramas.component';
import { DatasetListviewParticipatesComponent } from '../listviews/participates/participates.component';
import { DatasetListviewShapesComponent } from '../listviews/shapes/shapes.component';
import { StateSettingsEditorComponent } from '../state-settings-editor/state-settings-editor.component';

export const DatasetChildRoutes: Routes = [
	{ path: 'fences', loadComponent: () => DatasetListviewFencesComponent },
	{ path: 'layers', loadComponent: () => DatasetListviewLayersComponent },
	{ path: 'markers', loadComponent: () => DatasetListviewMarkersComponent },
	{ path: 'markerCategories', loadComponent: () => DatasetListviewMarkerCategoriesComponent },
	{ path: 'panoramas', loadComponent: () => DatasetListviewPanoramasComponent },
	{ path: 'objects', loadComponent: () => DatasetListviewObjectsComponent },
	{ path: 'participates', loadComponent: () => DatasetListviewParticipatesComponent },
	{ path: 'photoCategories', loadComponent: () => DatasetListviewGeoPhotosComponent },
	{ path: 'shapes', loadComponent: () => DatasetListviewShapesComponent },
	{ path: ':index/state', loadComponent: () => StateSettingsEditorComponent },
	{ path: '**', redirectTo: 'fences' }
];

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MapUiButtonsEditorComponent } from '../map-ui-buttons-editor/map-ui-buttons-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./internal-view.component').then(m => m.MapUiComponent),
		children: [
			{ path: 'create', component: MapUiButtonsEditorComponent },
			{ path: 'edit/:id', component: MapUiButtonsEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MapUiRoutingModule {}

import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { TextfieldInjectionToken, TextfieldToken } from './textfield.injection.token';

export const TextfieldTransLoco = {
	provide: TRANSLOCO_SCOPE,
	useValue: {
		scope: 'textfields'
		// loader: scopeLoader(// (lang: string, root: string) => import(`/assets/${root}/${lang}.json`))
	}
};

export function provideTextfieldConfig(): EnvironmentProviders {
	return makeEnvironmentProviders([
		{ provide: TextfieldToken, useValue: TextfieldInjectionToken }
	]);
}

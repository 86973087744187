import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent],
	selector: 'yuno-admin-atlas-navbar-color-settings',
	templateUrl: './atlas-navbar-color-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasNavbarColorSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
}

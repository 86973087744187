import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, startWith, tap } from 'rxjs';

import { EventForm, EventFormsService, EventsFacade } from '@yuno/admin/features/events';
import { Event, MapUiButtons } from '@yuno/api/interface';

import { MapUiButtonsFacade } from '../../data-access';

export interface MapUiForm {
	_id?: FormControl<string>;
	id: FormControl<string>;
	icon: FormControl<string>;
	desktop: FormControl<string>;
	mobile: FormControl<string>;
	events: FormArray<FormGroup<EventForm>>;
}

@Injectable()
export class MapUiButtonsEditorService {
	private readonly mapUiFacade = inject(MapUiButtonsFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly eventsForm = inject(EventFormsService);

	originalData: Partial<MapUiButtons>;
	changesMade = 0;

	data$ = combineLatest({
		selected: this.mapUiFacade.selected$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as MapUiButtons;

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.form.patchValue(this.originalData as any);
					const events = data?.events;
					if (events && events.length >= 1) {
						const count = events.length;
						if (count > 0) {
							for (let i = 0; i < count; i++) {
								this.events.push(
									this.eventsForm.createEvent(events[i].type, events[i].options)
								);
							}
						}
					}
				}
			})
		),
		toggle: this.eventsFacade.toggle$.pipe(startWith(false))
	});

	onSelect(id: string | null) {
		this.mapUiFacade.select(id);
	}

	onToggleEvents() {
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.updateEvents(this.events.getRawValue() as Event[]);
		this.eventsFacade.setKey('events');
	}

	onSave() {
		this.mapUiFacade.save();
	}

	onUpdate() {
		this.mapUiFacade.update(this.form.getRawValue() as MapUiButtons);
	}

	onClose(): void {
		this.mapUiFacade.clearSelect();
	}

	form: FormGroup<MapUiForm>;

	iconMapButtonValues = [
		'plane',
		'rocket',
		'globe',
		'search',
		'three-dots',
		'marker',
		'info',
		'question',
		'summary',
		'links',
		'documents',
		'notification',
		'settings',
		'photo',
		'image',
		'layers',
		'book',
		'talk'
	];
	iconMapButtonDisplay = [
		'Plane',
		'Rocket',
		'Glove',
		'Search',
		'3 Dots',
		'Marker',
		'Info',
		'Question',
		'Summary',
		'Links',
		'Documents',
		'Notification',
		'Settings',
		'Photo',
		'Image',
		'Layers',
		'Book',
		'Talk'
	];

	locMapButtonValues = ['hidden', 'top-left', 'top-right', 'bottom-left', 'bottom-right'];
	locMapButtonDisplay = ['Hidden', 'Top Left', 'Top Right', 'Bottom Left', 'Bottom Right'];

	get events() {
		return this.form.get('events') as FormArray<FormGroup<EventForm>>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<MapUiForm>({
			id: new FormControl('', { nonNullable: true }),
			icon: new FormControl('plane', { nonNullable: true }),
			desktop: new FormControl('hidden', { nonNullable: true }),
			mobile: new FormControl('hidden', { nonNullable: true }),
			events: new FormArray<FormGroup<EventForm>>([])
		});
	}
}

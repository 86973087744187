import { Injectable } from '@angular/core';
import { ReplaySubject, of } from 'rxjs';

import { RoutesInterface, RoutesItemDropdown } from '@yuno/api/interface';

@Injectable()
export class ClickService {
	// Keeps track of the last clicked item
	private readonly _item = new ReplaySubject<RoutesInterface | RoutesItemDropdown>(1);

	/* Exposed observable (read-only). */
	readonly item$ = this._item.asObservable();

	setLastClick(item: RoutesInterface | RoutesItemDropdown) {
		this._item.next(item);
	}
}

export const ClickServiceMock = {
	item$: of({
		display: 'clustering',
		dropdown: [],
		events: [],
		link: 'clustering',
		public: true,
		theme: '61937e692881275ccafacd9d'
	}),
	setLastClick: (item: RoutesInterface | RoutesItemDropdown) => {
		return item;
	}
};

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeIframe',
	standalone: true
})
export class SafeIframePipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	transform(value?: string): unknown {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value || '');
	}
}

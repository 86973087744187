<a
	#rla="routerLinkActive"
	routerLinkActive
	[routerLink]="link"
	[routerLinkActive]="'active'"
	[routerLinkActiveOptions]="{ exact: exact }"
	[ngClass]="{
		'pointer-events-auto text-gray-600': !rla.isActive,
		'hover:text-[#0EA5E9]': link && !rla.isActive,
		'pointer-events-none text-[#0EA5E9]': rla.isActive || active,
		truncate: !first
	}"
	tabindex="0"
	class="select-none text-xl font-semibold transition-all duration-100">
	<ng-content select="[svg, .svgInline]"></ng-content>
	<span>{{ display }}</span>
</a>
@if (!last) {
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="pointer-events-none h-3 w-3 -rotate-90 transform"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor">
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7" />
	</svg>
}

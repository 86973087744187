<yuno-admin-navbar-buttons-container>
	<button yuno-admin-save-button (click)="onSave()"></button>
</yuno-admin-navbar-buttons-container>
<div class="mb-8 flex flex-col gap-8">
	<yuno-card>
		<ng-container title>Team</ng-container>
		<div content class="grid grid-cols-1 gap-4">
			<form [formGroup]="service.appForm" class="mb-6 flex flex-col gap-6">
				<ng-container formGroupName="data">
					@if (users$ | async; as users) {
						<section class="grid grid-cols-2 gap-4">
							<yuno-forms-select
								formControlName="lead"
								label="Atlas Lead"
								[placeholder]="'select atlas lead...'"
								[display]="users.xkpDisplay"
								[selectValues]="users.xkpValue" />
							<yuno-forms-select
								formControlName="engineer"
								label="Visual Engineer"
								[placeholder]="'select visual engineer...'"
								[display]="users.xkpDisplay"
								[selectValues]="users.xkpValue" />
							<yuno-forms-select
								class="col-span-2"
								formControlName="contact"
								label="Contact"
								[placeholder]="'select contact...'"
								[display]="users.clientDisplay"
								[selectValues]="users.clientValue" />
						</section>
					}
				</ng-container>
			</form>
		</div>
	</yuno-card>
</div>

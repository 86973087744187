import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
	selector: 'yuno-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
	private _spinnerSize = 40;
	private _spinnerWidth = 4;

	@Input()
	get spinnerSize(): number {
		return this._spinnerSize;
	}
	set spinnerSize(num: number) {
		this._spinnerSize = num;
		this.renderer.setStyle(this.elementRef.nativeElement, '--spinner-size', num, 2);
	}

	@Input()
	get spinnerWidth(): number {
		return this._spinnerWidth;
	}
	set spinnerWidth(num: number) {
		this._spinnerWidth = num;
		this.renderer.setStyle(this.elementRef.nativeElement, '--spinner-width', num, 2);
	}
	@Input() set spinnerColor(str: string) {
		this.renderer.setStyle(this.elementRef.nativeElement, '--spinner-color', str, 2);
	}
	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2
	) {}
}

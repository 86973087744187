<yuno-edit-container [col]="4" [pos]="1" [span]="2">
	<ng-container buttons>
		<button yuno-admin-button color="success" (click)="onSave()">Save</button>
		<button yuno-admin-close-button (click)="onClose()"></button>
	</ng-container>
	<div edit-container-content>
		<div class="mb-8">
			<input
				id="inputfile"
				type="file"
				accept=".svg"
				(change)="service.onFileChange($event)"
				[multiple]="true" />
			@if (success) {
				<div
					class="my-4 flex flex-col gap-2 rounded-md border border-green-500 bg-green-50 p-4 md:p-6">
					<div class="text-xl font-semibold">Icon successfully uploaded!</div>
					<div>
						If you've added a new icon, you might need to refresh the page to see your
						new icon.
					</div>
				</div>
			}
			<h4 class="ui divider"></h4>
			<img
				src="{{ environment['yuno-cdn'] }}/sprites/svg-export-settings.png"
				alt="sprite export settings" />
		</div>
	</div>
</yuno-edit-container>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TextfieldEditorComponent } from '../editor/textfield-editor/textfield-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./textfields.component').then(m => m.TextfieldsComponent),
		children: [
			{ path: 'create', component: TextfieldEditorComponent },
			{ path: 'edit/:id', component: TextfieldEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TextfieldsRoutingModule {}

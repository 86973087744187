import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as appDataEffects from './+state/app-data.effects';
import { AppDataFacade } from './+state/app-data.facade';
import { AppDataFeature } from './+state/app-data.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(AppDataFeature), provideEffects([appDataEffects]), AppDataFacade]
})
export class AppDataDataAccessModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as appEffects from './+state/app.effects';
import { AppFacade } from './+state/app.facade';
import { appFeature } from './+state/app.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(appFeature), provideEffects([appEffects]), AppFacade]
})
export class AppsDataAccessModule {}

<yuno-admin-navbar-buttons-container>
	<button yuno-admin-button color="success" (click)="create()">Save</button>
</yuno-admin-navbar-buttons-container>
<div class="flex flex-col gap-8">
	@if (data$ | async) {
		<yuno-forms-title>Create new Application</yuno-forms-title>
		@if (service.form; as form) {
			<form [formGroup]="form" class="grid grid-cols-1 gap-4">
				<div class="grid grid-cols-4 gap-2">
					<yuno-forms-text
						class="col-span-3"
						formControlName="id"
						placeholder="Application Title"
						label="Application Title">
						@if (service.form.get('id')?.errors?.['required']) {
							<span> give the application a title! </span>
						}
						@if (service.form.get('id')?.errors?.['minlength']) {
							<span>
								the application should be atleast:
								{{ service.form.get('id')?.errors?.['minlength'].requiredLength }}
								characters long!
							</span>
						}
					</yuno-forms-text>
					<yuno-forms-select
						class="col-span-1"
						formControlName="language"
						placeholder="Choose Language"
						[selectValues]="['nl', 'de', 'en', 'fr', 'es', 'it', 'da']"
						[display]="[
							'Nederlands',
							'Duits',
							'Engels',
							'Frans',
							'Spaans',
							'Italiaans',
							'Deens'
						]"
						label="Language"></yuno-forms-select>
				</div>
				<div class="grid grid-cols-1">
					<yuno-forms-title class="mt-6">Urls</yuno-forms-title>
					<yuno-forms-span>the first url will also be the foldername </yuno-forms-span>
				</div>
				<yuno-forms-array-container
					[control]="service.urls"
					formArrayName="url"
					cdkDropList
					(cdkDropListDropped)="drop($event)">
					<div arrayBody class="grid grid-cols-1 gap-2">
						@for (url of service.urls.controls; track url; let i = $index) {
							<yuno-admin-drag-row [keepButtons]="true" cdkDrag cdkDragLockAxis="y">
								<div class="flex-1 select-none" title>
									<yuno-forms-text
										[formControlName]="i"
										placeholder="Application Url">
										@if (
											service.urls.controls[i].errors?.['minlength'] ||
											service.urls.controls[i].errors?.['required']
										) {
											<span> please provide at least 3 characters </span>
										}

										@if (
											service.urls.controls[i].errors?.[
												'noSpacesLowerCase'
											]?.['whitespace']
										) {
											<span> whitespaces are not allowed! </span>
										}

										@if (
											service.urls.controls[i].errors?.[
												'noSpacesLowerCase'
											]?.['uppercase']
										) {
											<span> only lowercase is allowed </span>
										}
									</yuno-forms-text>
								</div>
								<ng-container buttons>
									<button
										yuno-admin-button-table
										(click)="onRemove(i)"
										color="danger">
										delete
									</button>
								</ng-container>
							</yuno-admin-drag-row>
						}
					</div>
					<div arrayErrors>
						@if (!service.urls.valid && !service.urls.errors) {
							<span> one of the urls is not valid! </span>
						}
						@if (service.urls.errors?.['minlength']) {
							<span> please provide at least one url! </span>
						}
					</div>
				</yuno-forms-array-container>
				<div class="flex items-center gap-4">
					<button yuno-admin-button color="primary" [add]="true" (click)="onAdd()">
						Add Url
					</button>
				</div>
				<section class="my-8 flex flex-col gap-2">
					<yuno-forms-title>Select a Client</yuno-forms-title>
					<yuno-forms-select
						formControlName="client"
						placeholder="Choose Client..."
						[selectValues]="service.clientsSelect"
						[display]="service.clientsDisplay"
						label="Client">
						@if (service.form.get('client')?.errors?.['required']) {
							<span> please select a client! </span>
						}
					</yuno-forms-select>
				</section>

				<yuno-forms-title>Select a Location</yuno-forms-title>
				<div class="grid gap-8 lg:grid-cols-2">
					<div class="mb-6 flex flex-col gap-6">
						<section class="grid gap-4">
							<label class="yuno-form">
								<span class="select-none">Center Point</span>
								<div class="flex flex-row gap-4" formArrayName="coordinates">
									@for (
										coord of service.coordinates.controls;
										track coord;
										let i = $index
									) {
										<yuno-forms-number [step]="0.001" [formControlName]="i">
										</yuno-forms-number>
									}
								</div>
							</label>
						</section>
						<section>
							<h4>Extend</h4>
							<div formArrayName="extend" class="order-first grid gap-2">
								<div
									[formArrayName]="1"
									class="grid grid-cols-3 items-center justify-center">
									<yuno-forms-span
										class="col-span-3 col-start-3 mr-2 text-right font-semibold">
										top right
									</yuno-forms-span>
									<div class="col-span-3 col-start-1 gap-2">
										<div class="mb-1 flex items-center gap-2">
											<span>x:</span>
											<yuno-forms-number
												class="w-full"
												[formControlName]="0"
												[step]="0.01"></yuno-forms-number>
										</div>
										<div class="mb-1 flex items-center gap-2">
											<span>y:</span>
											<yuno-forms-number
												class="w-full"
												[formControlName]="1"
												[step]="0.01"></yuno-forms-number>
										</div>
									</div>
								</div>
								<div
									[formArrayName]="0"
									class="grid grid-cols-3 items-center justify-center">
									<yuno-forms-span class="col-span-3 font-semibold"
										>bottom left</yuno-forms-span
									>
									<div class="col-span-3 gap-2">
										<div class="mb-1 flex items-center gap-2">
											<span>x:</span>
											<yuno-forms-number
												class="w-full"
												[formControlName]="0"
												[step]="0.01"></yuno-forms-number>
										</div>
										<div class="mb-1 flex items-center gap-2">
											<span>y:</span>
											<yuno-forms-number
												class="w-full"
												[formControlName]="1"
												[step]="0.01"></yuno-forms-number>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>

					<yuno-admin-config-map
						(pointer)="service.onUpdateCoordinates($event)"
						(polygon)="service.onUpdateExtend($event)"
						[extend]="service.extend.value"
						[coordinates]="service.coordinates.value"></yuno-admin-config-map>
				</div>
			</form>
		}
	}
</div>

export const textfieldElements: string[] = [
	'yuno-text-block',
	'yuno-text-button',
	'yuno-text-collapsible',
	'yuno-text-embed',
	'yuno-text-image',
	'yuno-text-link',
	'yuno-text-toggles',
	'yuno-text-toggles-toggle',
	'yuno-text-video',
	'yuno-text-list',
	'yuno-text-list-item',
	'yuno-text-list-dropdown'
];

/**
 * BASED ON
 * https://stackoverflow.com/a/61487218
 *
 * Basicly, when overwriting the State with a undefined, value.
 * It reassigns its initialState
 */
import { appActions } from '@yuno/admin/features/apps';
import { authActions } from '@yuno/angular-auth';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function clearCurrentAppState(reducer: any) {
	return function (state: any, action: any) {
		if (action.type === appActions.appClearState) {
			// We should keep a few of the States intact
			const { navbar, auth, layout } = state;
			state = { navbar, auth, layout };
		}
		return reducer(state, action);
	};
}

export function logout(reducer: any) {
	return function (state: any, action: any) {
		if (action.type === authActions.logout) {
			state = undefined;
		}
		return reducer(state, action);
	};
}

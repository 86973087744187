import { AnimationBuilder, AnimationMetadata, animate, style } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
	Directive,
	ElementRef,
	HostBinding,
	HostListener,
	Inject,
	Input,
	NgModule,
	NgZone,
	PLATFORM_ID,
	Renderer2
} from '@angular/core';

const defaultRippleColor = 'rgba(255, 255, 255, 0.2)';
const enum RippleState {
	FADE_IN,
	VISIBLE,
	FADE_OUT,
	HIDDEN
}

export const defaultRippleAnimationTiming = {
	enterDuration: 222,
	exitDuration: 125
};

class RippleContainer {
	container: HTMLElement;
	state: RippleState;

	constructor(obj: RippleContainer) {
		this.container = obj.container;
		this.state = obj.state;
	}
}

@Directive({
	selector: '[yuno-ripple], [yuno-ripple-standalone], [yunoRipple]'
})
export class RippleDirective {
	/* stores the element that has this directive */
	private _container: HTMLElement;
	/* keep latest ripple in memory */
	private _latestRipple: RippleContainer | null;
	/* track all non hidden ripples */
	private _activeRipples = new Set<RippleContainer>();
	/* when pointer is down */
	private _isPointerDown = false;

	/* stores the changable color */
	private _color = defaultRippleColor;

	/*
	 * default styling of the ripple effect
	 * should ben a position of relative. To easier use
	 * inside other components
	 */
	@HostBinding('style.position') position = 'relative';

	/*
	 * overflow should always be hidden. To no show
	 * the ripple extending it's parent
	 */
	@HostBinding('style.overflow') overflow = 'hidden';

	/*
	 * sets the color of the ripple effect
	 * defaults to a transparent white color
	 */
	@Input('yunoRippleColor')
	set color(color: string) {
		if (!color) {
			this._color = defaultRippleColor;
			return;
		}

		this._color = color;
	}

	/* returns the given color */
	get color(): string {
		return this._color;
	}

	/* duration of the ripple effect */
	@Input('yunoRippleDuration') duration = defaultRippleAnimationTiming.enterDuration;

	/*
	 * listen to pointer down events when the pointer
	 * is down, the last ripple will not dissapear
	 */
	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	onPointerDown(event: MouseEvent) {
		this._isPointerDown = true;
		this.activateRipple(event);
	}

	/*
	 * listen to pointer up events
	 * so we can remove all ripples
	 */
	@HostListener('mouseup', ['$event'])
	@HostListener('mouseleave', ['$event'])
	@HostListener('touchend', ['$event'])
	@HostListener('touchcancel', ['$event'])
	onPointerUp() {
		this._isPointerDown = false;
		this.removeAll();
	}

	constructor(
		private ngZone: NgZone,
		private renderer: Renderer2,
		private animationBuilder: AnimationBuilder,
		private elementRef: ElementRef,
		@Inject(PLATFORM_ID) platform: string
	) {
		/* When rendering in the browser instead of ssr */
		if (isPlatformBrowser(platform)) {
			this._container = this.elementRef.nativeElement;

			/*
			 * sets the standalone styling
			 * when using as a standalone object, the ripple
			 * effect should cover the complete parent object.
			 */
			if (this._hasHostAttributes('yuno-ripple-standalone')) {
				this.position = 'absolute';
				this.renderer.setStyle(this._container, 'borderRadius', 'inherit');
				this.renderer.setStyle(this._container, 'top', '0px');
				this.renderer.setStyle(this._container, 'left', '0px');
				this.renderer.setStyle(this._container, 'right', '0px');
				this.renderer.setStyle(this._container, 'bottom', '0px');
			}
		}
	}

	/*
	 * creates a ripple container with all styling
	 * using the renderer2 of angular
	 */
	private activateRipple(event: MouseEvent): void {
		const ripple = this.renderer.createElement('span');

		const diameter = Math.max(this._container.clientWidth, this._container.clientHeight);
		const radius = diameter / 2;

		const containerRect = this._container.getBoundingClientRect();
		const positionX = event.clientX - containerRect.left - radius;
		const positionY = event.clientY - containerRect.top - radius;

		/* Sets the styling of the ripple using Renderer2 */
		this.renderer.addClass(ripple, 'yuno-ripple-element');
		this.renderer.setStyle(ripple, 'position', 'absolute');
		this.renderer.setStyle(ripple, 'pointerEvents', 'none');
		this.renderer.setStyle(ripple, 'borderRadius', '50%');
		this.renderer.setStyle(ripple, 'transform', 'scale(0)');
		this.renderer.setStyle(ripple, 'backgroundColor', this.color);
		this.renderer.setStyle(ripple, 'boxShadow', `0px 0px 24px 12px ${this.color}`);
		this.renderer.setStyle(ripple, 'height', `${diameter}px`);
		this.renderer.setStyle(ripple, 'width', `${diameter}px`);
		this.renderer.setStyle(ripple, 'left', `${positionX}px`);
		this.renderer.setStyle(ripple, 'top', `${positionY}px`);

		const rippleRef = new RippleContainer({
			container: ripple,
			state: RippleState.FADE_IN
		});

		this._activeRipples.add(rippleRef);
		this._latestRipple = rippleRef;

		this.renderer.appendChild(this._container, rippleRef.container);

		const factory = this.animationBuilder.build(this.rippleFadeIn());
		const player = factory.create(rippleRef.container);

		player.play();

		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				const latest = this._latestRipple === rippleRef;
				rippleRef.state = RippleState.VISIBLE;

				/*
				 * When the timer is done and pointer is up
				 * remove the element. Else we want to keep
				 * the effect and remove it on the Pointer Up Event
				 */
				if (!latest || !this._isPointerDown) {
					this.removeRipple(rippleRef);
				}
			}, this.duration);
		});
	}

	/* Remove a single ripple effect */
	private removeRipple(rippleRef: RippleContainer): void {
		rippleRef.state = RippleState.FADE_OUT;
		this._activeRipples.delete(rippleRef);

		if (rippleRef === this._latestRipple) {
			this._latestRipple = null;
		}

		const factory = this.animationBuilder.build(this.rippleFadeOut());
		const player = factory.create(rippleRef.container);

		player.play();

		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				rippleRef.state = RippleState.HIDDEN;
				this.renderer.removeChild(this._container, rippleRef.container);
			}, defaultRippleAnimationTiming.exitDuration);
		});
	}

	/* Removes all VISIBLE ripple containers */
	private removeAll(): void {
		this._activeRipples.forEach(ripple => {
			ripple.state === RippleState.VISIBLE && this.removeRipple(ripple);
		});
	}

	/* play the scaling animation */
	private rippleFadeIn(): AnimationMetadata[] {
		return [
			style({
				transform: 'scale(0)'
			}),
			animate(
				`${this.duration}ms ease-in`,
				style({
					transform: 'scale(4)'
				})
			)
		];
	}

	/* play the fadeout animation */
	private rippleFadeOut(): AnimationMetadata[] {
		return [
			style({
				opacity: 1
			}),
			animate(
				`${defaultRippleAnimationTiming.exitDuration}ms ease-out`,
				style({
					opacity: 0
				})
			)
		];
	}

	/** Gets whether the button has one of the given attributes. */
	_hasHostAttributes(...attributes: string[]): boolean {
		return attributes.some(attribute => this.elementRef.nativeElement.hasAttribute(attribute));
	}
}

@NgModule({
	imports: [],
	declarations: [RippleDirective],
	exports: [RippleDirective]
})
export class RippleDirectiveModule {}

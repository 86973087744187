import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { Config } from '@yuno/api/interface';

export const configResolver: ResolveFn<Partial<Config> | undefined> = (): Observable<
	Partial<Config> | undefined
> => {
	const store = inject(Store);
	return store.select(appFeature.selectConfig);
};

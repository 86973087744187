<button
	(focus)="setFocus()"
	(mousedown)="removeFocus()"
	tabindex="0"
	class="mt-1 grid w-full transform cursor-pointer grid-cols-1 overflow-hidden rounded-lg text-base transition-all duration-500 ease-in-out focus:bg-gray-900"
	[ngClass]="{
		'max-h-screen text-gray-300': isActive,
		'text-gray-400 hover:bg-gray-900 md:max-h-[42px]': !isActive
	}">
	<span class="inline-flex items-center px-4 py-2">
		<ng-content select="[svg, .svgInline]"></ng-content>
		<span class="truncate">{{ display }}</span>

		<div class="hidden flex-1 flex-row justify-end md:flex">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-4 w-4"
				[ngClass]="{ 'scale-y-[-1] transform': isActive }"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M19 9l-7 7-7-7" />
			</svg>
		</div>
	</span>

	<ul #listContainer class="list-none px-4 pb-2">
		<ng-content></ng-content>
	</ul>
</button>

import { Pipe, PipeTransform } from '@angular/core';

import { LanguageStringsModel } from '@yuno/api/interface';
import { LanguagePipeFunction } from '@yuno/shared/helpers';


@Pipe({
	name: 'languageSelect',
	standalone: true
})
export class LanguagePipe implements PipeTransform {
	transform(
		value: LanguageStringsModel | undefined,
		language?: string,
		fallBack?: string
	): string {
		// using Nunkjucks we can use numbers and booleans
		// in Angular we only use Strings so we convert the given value
		// back to a string
		return LanguagePipeFunction(value, language, fallBack).toString();
	}
}

import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { CreateAppDto } from '@yuno/api/dto';

import { createAppActions } from './create-app.actions';
import { createAppFeature } from './create-app.state';

@Injectable()
export class CreateAppFacade {
	private readonly store = inject(Store);

	status$ = this.store.pipe(select(createAppFeature.selectStatus));
	errors$ = this.store.pipe(select(createAppFeature.selectError));

	createApp(dto: CreateAppDto) {
		this.store.dispatch(createAppActions.createApp({ dto }));
	}
}

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, of, switchMap } from 'rxjs';

import { GeoPhotoCategoryFacade } from '@yuno/admin/features/geophotos';

export const geoPhotosGuard: CanActivateFn = route => {
	const { id } = route.params;
	if (!id) {
		return false;
	}

	if (id === 'create') {
		return true;
	}

	const facade = inject(GeoPhotoCategoryFacade);
	facade.clearSelected();
	facade.select(id);

	return facade.selected$.pipe(
		switchMap(() => of(true)),
		catchError(() => of(false))
	);
};

export const geoPhotosListGuard: CanActivateFn = () => {
	const facade = inject(GeoPhotoCategoryFacade);
	facade.get();
	facade.clearSelected();

	return facade.data$.pipe(
		switchMap(() => of(true)),
		catchError(() => of(true))
	);
};

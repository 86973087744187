import { isPlatformBrowser } from '@angular/common';
import {
	Directive,
	ElementRef,
	EventEmitter,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	PLATFORM_ID,
	inject
} from '@angular/core';

export class ResizedEvent {
	newRect: DOMRectReadOnly;
	oldRect?: DOMRectReadOnly;
	isFirst: boolean;

	constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
		this.newRect = newRect;
		this.oldRect = oldRect;
		this.isFirst = oldRect == null;
	}
}

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[resized]',
	standalone: true
})
export class ResizedDirective implements OnInit, OnDestroy {
	private plaform = inject(PLATFORM_ID);

	private observer: ResizeObserver;
	private oldRect?: DOMRectReadOnly;

	@Output() readonly resized = new EventEmitter<ResizedEvent>();

	constructor(
		private readonly element: ElementRef,
		private readonly zone: NgZone
	) {
		if (isPlatformBrowser(this.plaform)) {
			this.resized = new EventEmitter<ResizedEvent>();
			this.observer = new ResizeObserver(entries =>
				this.zone.run(() => this.observe(entries))
			);
		}
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.plaform)) {
			this.observer?.observe(this.element.nativeElement);
		}
	}

	ngOnDestroy(): void {
		if (isPlatformBrowser(this.plaform)) {
			this.observer?.disconnect();
		}
	}

	private observe(entries: ResizeObserverEntry[]): void {
		if (isPlatformBrowser(this.plaform)) {
			const domSize = entries[0];
			const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
			this.oldRect = domSize.contentRect;
			this.resized?.emit(resizedEvent);
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

import { safeHtmlOptions } from './helpers';

@Pipe({
	name: 'safeHtml',
	standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	public transform(value?: string | HTMLElement | undefined): SafeHtml {
		if (!value) {
			return '';
		}

		if (this.isHTMLElement(value)) {
			value = (value as HTMLElement).outerHTML;
		}

		const sanitizedHtml = sanitizeHtml(value as string, safeHtmlOptions);
		return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
	}

	isHTMLElement(value: string | HTMLElement | undefined): boolean {
		return value instanceof HTMLElement;
	}
}

<yuno-admin-navbar-buttons-container>
	<button yuno-admin-save-button (click)="onSave()"></button>
</yuno-admin-navbar-buttons-container>
<div class="mb-8 flex flex-col gap-8">
	<yuno-card
		[noPadding]="true"
		[transparent]="true"
		[tabs]="['Settings', 'Location']"
		(outputTab)="service.tabProject = $event.key">
		<ng-container title>{{ service.tabProject }}</ng-container>
		<div content class="grid grid-cols-1 gap-4">
			@if (!minimalAppRole(userRoles.EDITOR)) {
				<yuno-user-rights-message />
			}
			@if (service.tabProject === 'Settings') {
				<yuno-admin-project-title-settings />
			}

			@if (service.tabProject === 'Location') {
				<yuno-admin-project-location-settings />
			}
		</div>
	</yuno-card>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as createAppEffects from './+state/create-app.effects';
import { CreateAppFacade } from './+state/create-app.facade';
import { createAppFeature } from './+state/create-app.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(createAppFeature), provideEffects([createAppEffects]), CreateAppFacade]
})
export class AppCreateDataAccessModule {}

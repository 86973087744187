import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	inject
} from '@angular/core';
import { kebabCase } from 'lodash';
import { Map } from 'maplibre-gl';
import { combineLatest, tap } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageObjectModel, Legend, LegendOptions } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';

import { LegendItemComponent } from '../legend-item/legend-item.component';
import { LegendService, legendAnimationTime } from '../legend.service';

@Component({
	selector: 'yuno-legend-container',
	standalone: true,
	imports: [CommonModule, LegendItemComponent, LanguagePipe],
	templateUrl: './legend-container.component.html',
	styleUrls: ['./legend-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendContainerComponent implements AfterViewInit {
	private readonly service = inject(LegendService);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly el = inject(ElementRef);

	@HostBinding('class.active') active = false;
	@HostBinding('class.overflow') overflow = false;

	@Input() map: Map;
	@Input() title: string;

	_isOpen = false;
	@Input() set isOpen(bool: boolean) {
		this._isOpen = bool;

		if (bool) {
			this.ngAfterViewInit();
		}
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	@Output() isOpenChange = new EventEmitter<boolean>(false);

	_data: Partial<Legend>;
	@Input() set data(legend: Partial<Legend>) {
		// disable scrolling
		this.overflow = false;

		// Set the data
		this._data = legend;

		// Update the height to current items
		this.updateHeight();
	}

	get data(): Partial<Legend> {
		return this._data;
	}

	_maxHeight = 500;
	@Input() set maxHeight(height: number) {
		this._maxHeight = height;
		this.updateHeight();
	}

	get maxHeight(): number {
		return this._maxHeight;
	}

	@Input() language: string | undefined = 'nl';

	data$ = combineLatest({
		closeSlow: this.service.closeSlow$.pipe(
			tap(bool => {
				bool && this.close();
			})
		)
	});

	async close(): Promise<void> {
		this.active = false;
		this.overflow = false;
		this.cdr.markForCheck();

		await waitFor(legendAnimationTime);

		this.isOpen = false;
		this.isOpenChange.emit(this.isOpen);
	}

	async ngAfterViewInit(): Promise<void> {
		// Need to wait to have a nice transition
		await waitFor();

		// Animate the Container
		this.active = true;
		this.cdr.markForCheck();

		// Wait for animation to complete
		await waitFor(legendAnimationTime);

		// enable scrollbar when needed
		this.overflow = true;
		this.cdr.markForCheck();
	}

	async updateHeight(): Promise<void> {
		if (!this.data) {
			return;
		}
		await waitFor(100);

		// default padding
		let height = 32;

		// loop through all children
		for (const elem of this.el.nativeElement.childNodes) {
			height += Number(elem.clientHeight || 0);
		}

		if (this.maxHeight && height > this.maxHeight) {
			height = this.maxHeight;
		}

		// set legend-height css variable
		this.el.nativeElement.style.setProperty('--legend-height', height + 'px');

		await waitFor(legendAnimationTime);
		this.overflow = true;

		this.cdr.markForCheck();
	}

	trackByFn(index: number, item: LegendOptions) {
		if (!item.class) {
			return index;
		}

		let title: string;
		if (typeof item.title === 'string') {
			title = item.title;
		} else {
			title = (item.title as LanguageObjectModel)[this.language || 'nl'] || 'rand';
		}

		return kebabCase(`${item.class}-${item.color}-${title}`);
	}
}

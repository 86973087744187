import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	NgZone,
	OnInit,
	Optional,
	Renderer2
} from '@angular/core';
import { Observable, catchError, map, take, throwError } from 'rxjs';

/**
 * Based on https://github.com/czeckd/angular-svg-icon
 * We only need a very simple version of the Library
 * This is the bare minimum
 */
@Component({
	selector: 'yuno-svg[src]',
	template: `<ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class SvgComponent implements OnInit {
	private document: Document;
	private observer: ResizeObserver;
	private icon: SVGElement;

	_src: string;

	/**
	 * Load SVG from the src url
	 */
	@Input() set src(src: string) {
		this._src = src;

		// start loading the SVG based on the Src
		this.loadSvg()
			.pipe(take(1))
			.subscribe(svg => this.setSvg(svg));
	}

	get src(): string {
		return this._src;
	}

	_fill = 'inherit';
	/**
	 * Colorize the Fill value
	 * when a Path inside the SVG does not have a Fill
	 * it will take on this color
	 *
	 * Defaults to '#fff'
	 */
	@Input() set fill(fill: string | undefined) {
		this._fill = fill || 'inherit';
	}

	get fill(): string {
		return this._fill;
	}

	/**
	 * Should the SVG try to fit inside the parent container
	 * When False, it keeps its own width/height or viewcontainer
	 * When True, we set the width/height to this container
	 */
	@Input() parentScale = false;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private http: HttpClient,
		private readonly zone: NgZone,
		@Optional() @Inject(DOCUMENT) private _document: Document
	) {
		this.document = this._document;
		this.observer = new ResizeObserver(() => this.zone.run(() => this.resizeSvg()));
	}

	ngOnInit(): void {
		// Observe the Resizes of this element
		// needed to adjust the size of the SVG element
		this.observer.observe(this.el.nativeElement);
	}

	// Load the SVG from a URL as Observable
	loadSvg(): Observable<SVGElement | undefined> {
		return this.getSvg().pipe(
			map(svg => {
				const div = this.document.createElement('DIV');
				div.innerHTML = svg;
				return div.querySelector('svg') as SVGElement;
			}),
			catchError(err => {
				return throwError(() => new Error(err));
			})
		) as Observable<SVGElement>;
	}

	// HTTP request of the SVG
	getSvg(): Observable<string> {
		return this.http.get(this.src, { responseType: 'text' });
	}

	// Create the SVG
	setSvg(svg?: SVGElement): void {
		if (!svg) {
			return;
		}

		// Returns a copy of node, including the node's descendants.
		this.icon = svg.cloneNode(true) as SVGElement;
		const elem = this.el.nativeElement;

		// Clear the component HTML
		elem.innerHTML = '';

		// Add the SVG
		this.renderer.appendChild(elem, this.icon);

		// Colorize the Fill value
		// when a Path inside the SVG does not have a Fill
		// it will take on this color
		this.renderer.setAttribute(this.icon, 'fill', this.fill || '#fff');

		// Set SVG sizing
		this.resizeSvg();

		// update the view
		this.cdr.markForCheck();
	}

	resizeSvg(): void {
		if (!this.parentScale || !this.icon) {
			return;
		}

		this.renderer.setAttribute(this.icon, 'width', this.el.nativeElement.clientWidth);
		this.renderer.setAttribute(this.icon, 'height', this.el.nativeElement.clientHeight);
	}
}

import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { Position } from 'geojson';

import {
	ArrayValidators,
	CustomValidators,
	LanguageFormType,
	newLanguageFormGroup
} from '@yuno/angular/forms';
import {
	LanguagesArray,
	LanguagesDisplayArray,
	Textfield,
	helpDefaultDesktopText,
	helpDefaultMobileText
} from '@yuno/api/interface';
import { round } from '@yuno/shared/helpers';

interface AppForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	public: FormControl<boolean>;
	trackingId: FormControl<number>;
	url: FormArray<FormControl<string>>;
	client: FormGroup<{ _id: FormControl<string> }>;
	data: FormGroup<{
		lead: FormControl<string>;
		engineer: FormControl<string>;
		contact: FormControl<string>;
		coordinates: FormArray<FormControl<number>>;
		extend: FormArray<FormArray<FormControl<number>>>; // [[number, number], [number, number]];
	}>;
}

interface ConfigColorsForm {
	additional: FormControl<string>;
	button: FormControl<string>;
	buttonFont: FormControl<string>;
	embedNavbar: FormGroup<{
		active: FormControl<string>;
		activeFont: FormControl<string>;
		background: FormControl<string>;
		font: FormControl<string>;
		underline: FormControl<boolean>;
	}>;
	locationMarker: FormControl<string>;
	measure: FormControl<string>;
	navbar: FormGroup<{
		active: FormControl<string>;
		activeFont: FormControl<string>;
		background: FormControl<string>;
		font: FormControl<string>;
		underline: FormControl<boolean>;
	}>;
	panoramas: FormControl<string>;
	panoramasClusterCount: FormControl<string>;
	states: FormGroup<{
		background: FormControl<string>;
		font: FormControl<string>;
	}>;
	textHeading: FormControl<string>;
	textLink: FormControl<string>;
}

interface ConfigHelpForm {
	textfields: FormGroup<{
		desktop: FormControl<string>;
		mobile: FormControl<string>;
	}>;
	popup: FormGroup<{
		display: FormGroup<LanguageFormType>;
	}>;
	target: FormControl<string>;
	active: FormControl<boolean>;
}

interface ConfigInterfaceForm {
	breadcrumbs: FormControl<boolean>;
	controlZoom: FormControl<boolean>;
	embedType: FormControl<string>;
	help: FormGroup<{
		desktop: FormControl<string>;
		mobile: FormControl<string>;
	}>;
	mapOnly: FormControl<boolean>;
	navbarType: FormControl<string>;
	fullscreenButton: FormControl<boolean>;
	fullscreenPopup: FormControl<boolean>;
	embedFullscreenPopup: FormControl<boolean>;
	buttonStyle: FormControl<'default' | 'rounded' | 'square'>;
}

interface ConfigForm {
	colors: FormGroup<ConfigColorsForm>;
	epsg: FormControl<string>;
	help: FormGroup<ConfigHelpForm>;
	interface: FormGroup<ConfigInterfaceForm>;
	language: FormControl<string>;
	languages: FormArray<FormControl<string>>;
	path: FormControl<string>;
	title: FormControl<string>;
	customTitle: FormGroup<LanguageFormType>;
	fontHeader: FormControl<string>;
	fontParagraph: FormControl<string>;
}

@Injectable({
	providedIn: 'root'
})
export class InternalViewService {
	appForm = new FormGroup<AppForm>({
		_id: new FormControl<string>(
			{ value: '', disabled: true },
			{
				nonNullable: true
			}
		),
		public: new FormControl<boolean>(false, { nonNullable: true }),
		id: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		trackingId: new FormControl<number>(1, {
			nonNullable: true,
			validators: Validators.required
		}),
		url: new FormArray<FormControl<string>>([], ArrayValidators.minLength(1)),
		client: new FormGroup({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{ nonNullable: true, validators: Validators.required }
			)
		}),
		data: new FormGroup({
			lead: new FormControl<string>('', { nonNullable: true }),
			engineer: new FormControl<string>('', { nonNullable: true }),
			contact: new FormControl<string>('', { nonNullable: true }),
			coordinates: new FormArray<FormControl>([
				new FormControl<number>(4.463),
				new FormControl<number>(51.912)
			]),
			extend: new FormArray<FormArray<FormControl<number>>>([
				new FormArray([
					new FormControl(3.2, { nonNullable: true }),
					new FormControl(50.75, { nonNullable: true })
				]),
				new FormArray([
					new FormControl(7.22, { nonNullable: true }),
					new FormControl(53.7, { nonNullable: true })
				])
			])
		})
	});

	configForm = new FormGroup<ConfigForm>({
		colors: new FormGroup<ConfigColorsForm>({
			additional: new FormControl<string>('', { nonNullable: true }),
			button: new FormControl<string>('', { nonNullable: true }),
			buttonFont: new FormControl<string>('', { nonNullable: true }),
			embedNavbar: new FormGroup({
				active: new FormControl<string>('', { nonNullable: true }),
				activeFont: new FormControl<string>('', { nonNullable: true }),
				background: new FormControl<string>('', { nonNullable: true }),
				font: new FormControl<string>('', { nonNullable: true }),
				underline: new FormControl<boolean>(false, { nonNullable: true })
			}),
			locationMarker: new FormControl<string>('', { nonNullable: true }),
			measure: new FormControl<string>('', { nonNullable: true }),
			navbar: new FormGroup({
				active: new FormControl<string>('', { nonNullable: true }),
				activeFont: new FormControl<string>('', { nonNullable: true }),
				background: new FormControl<string>('', { nonNullable: true }),
				font: new FormControl<string>('', { nonNullable: true }),
				underline: new FormControl<boolean>(false, { nonNullable: true })
			}),
			panoramas: new FormControl<string>('', { nonNullable: true }),
			panoramasClusterCount: new FormControl<string>('', { nonNullable: true }),
			states: new FormGroup({
				background: new FormControl<string>('', { nonNullable: true }),
				font: new FormControl<string>('', { nonNullable: true })
			}),
			textHeading: new FormControl<string>('', { nonNullable: true }),
			textLink: new FormControl<string>('', { nonNullable: true })
		}),
		epsg: new FormControl<string>('', { nonNullable: true }),
		help: new FormGroup<ConfigHelpForm>({
			textfields: new FormGroup({
				desktop: new FormControl<string>('60d574f1bece0c2c33adfc6b', { nonNullable: true }),
				mobile: new FormControl<string>('60d57611bece0c2c33ae23c8', { nonNullable: true })
			}),
			popup: new FormGroup({
				display: newLanguageFormGroup()
			}),
			target: new FormControl<string>('full', { nonNullable: true }),
			active: new FormControl<boolean>(true, { nonNullable: true })
		}),
		interface: new FormGroup<ConfigInterfaceForm>({
			breadcrumbs: new FormControl<boolean>(false, { nonNullable: true }),
			controlZoom: new FormControl<boolean>(false, { nonNullable: true }),
			embedType: new FormControl<string>('', { nonNullable: true }),
			help: new FormGroup({
				desktop: new FormControl<string>('', { nonNullable: true }),
				mobile: new FormControl<string>('', { nonNullable: true })
			}),
			mapOnly: new FormControl<boolean>(false, { nonNullable: true }),
			navbarType: new FormControl<string>('', { nonNullable: true }),
			fullscreenButton: new FormControl<boolean>(false, { nonNullable: true }),
			fullscreenPopup: new FormControl<boolean>(false, { nonNullable: true }),
			embedFullscreenPopup: new FormControl<boolean>(false, { nonNullable: true }),
			buttonStyle: new FormControl<'default' | 'rounded' | 'square'>('default', {
				nonNullable: true
			})
		}),
		language: new FormControl<string>('', {
			nonNullable: true,
			validators: Validators.required
		}),
		languages: new FormArray<FormControl<string>>([]),
		path: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		title: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		customTitle: newLanguageFormGroup(),
		fontHeader: new FormControl<string>('', { nonNullable: true }),
		fontParagraph: new FormControl<string>('', { nonNullable: true })
	});

	textfieldsSelect: string[] = [];
	textfieldsDisplay: string[] = [];
	helpDefaultDesktopText: Array<Partial<Textfield>> = helpDefaultDesktopText;
	helpDefaultMobileText: Array<Partial<Textfield>> = helpDefaultMobileText;

	targetValues = ['right-full', 'left-full', 'right', 'left', 'full'];
	navbarTypeValues = ['default', 'border', 'hidden'];

	languageSelect = LanguagesArray;
	languageDisplay = LanguagesDisplayArray;

	languagesSelect = ['', ...LanguagesArray];
	languagesDisplay = ['Choose a language', ...LanguagesDisplayArray];

	fontSelect = [
		'',
		'rws',
		'ten',
		'pro',
		'gas',
		'autobahn',
		'rte',
		'bsk',
		'commissioner',
		'barlow'
	];
	fontDisplay = [
		'default',
		'Rijkswaterstaat',
		'TenneT',
		'Prorail',
		'Gasunie',
		'Die Autobahn',
		'RTE',
		'Boskalis',
		'Commissioner',
		'Barlow'
	];

	tabs = [];
	tabValue = 'UI';
	tabProject = 'Settings';

	get title(): FormControl<string> {
		return this.configForm.get('title') as FormControl<string>;
	}

	get languages(): FormArray<FormControl<string>> {
		return this.configForm.get('languages') as FormArray<FormControl<string>>;
	}

	get defaultHelpTextSelect(): string[] {
		const standard = this.helpDefaultDesktopText.map(text => text._id) as string[];
		return [...standard, ...this.textfieldsSelect];
	}

	get defaultHelpTextDisplay(): string[] {
		const standard = this.helpDefaultDesktopText.map(text => text.id) as string[];
		return [...standard, ...this.textfieldsDisplay];
	}

	get defaultHelpMobileSelect(): string[] {
		const standard = this.helpDefaultMobileText.map(text => text._id) as string[];
		return [...standard, ...this.textfieldsSelect];
	}

	get defaultHelpMobileDisplay(): string[] {
		const standard = this.helpDefaultMobileText.map(text => text.id) as string[];
		return [...standard, ...this.textfieldsDisplay];
	}

	get helpDisplay(): FormGroup<LanguageFormType> {
		return this.configForm.controls.help.controls.popup.get(
			'display'
		) as FormGroup<LanguageFormType>;
	}

	get helpTextfields(): ConfigHelpForm['textfields'] {
		return this.configForm.controls.help.get('textfields') as ConfigHelpForm['textfields'];
	}

	get urls(): FormArray<FormControl<string>> {
		return this.appForm.get('url') as FormArray<FormControl<string>>;
	}

	get urlValues(): string[] {
		return this.appForm.get('url')?.value || [];
	}

	get trackingId(): FormControl<number> {
		return this.appForm.get('trackingId') as FormControl<number>;
	}

	get appData(): FormGroup {
		return this.appForm.get('data') as FormGroup;
	}

	get coordinates(): FormArray {
		return this.appData.get('coordinates') as FormArray;
	}

	get extend(): FormArray {
		return this.appData.get('extend') as FormArray;
	}

	addUrl(str: string): void {
		const control = new FormControl<string>(str, {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(3),
				CustomValidators.noSpacesLowerCase()
			]
		});
		this.urls.push(control);
	}

	addNewUrl(): void {
		const control = new FormControl<string>('', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(3),
				CustomValidators.noSpacesLowerCase()
			]
		});
		this.urls.push(control);
	}

	removeUrl(index: number): void {
		this.urls.removeAt(index);
	}

	addLanguage(lang?: string): void {
		const control = new FormControl<string>(lang || '', {
			nonNullable: true
		});
		this.languages.push(control);
	}

	removeLanguage(index: number): void {
		this.languages.removeAt(index);
	}

	onUpdateCoordinates(coordinates: Position) {
		this.coordinates.controls[0].setValue(round(coordinates[0], 5));
		this.coordinates.controls[1].setValue(round(coordinates[1], 5));
	}

	onUpdateExtend(polygon: Position[]) {
		const line = lineString(polygon);
		const bb = bbox(line);

		const newBounds: [[number, number], [number, number]] = [
			[round(bb[0], 5), round(bb[1], 5)],
			[round(bb[2], 5), round(bb[3], 5)]
		];
		this.extend.patchValue(newBounds);
	}
}

import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import {
	AnnotationComponent,
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent, getIdFromRouter, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { MapUiButtonsEditorService } from './map-ui-buttons-editor.service';

@Component({
	selector: 'yuno-admin-map-ui-buttons-editor',
	standalone: true,
	imports: [
		CommonModule,
		YunoFormsModule,
		ReactiveFormsModule,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoAdminButtonComponent,
		EditContainerComponent,
		EditContainerContentDirective,
		EventsFormsComponent,
		AnnotationComponent
	],
	providers: [MapUiButtonsEditorService],
	templateUrl: './map-ui-buttons-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapUiButtonsEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	protected readonly redirectTo = redirectTo;
	private readonly destroyRef = inject(DestroyRef);

	readonly service = inject(MapUiButtonsEditorService);

	async ngOnInit() {
		this.service.createFormGroup();
		this.onChanges();
		if (!this.router.url.includes('create')) {
			this.service.onSelect(await getIdFromRouter(this.route));
		}
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.service.onUpdate();
				this.service.changesMade++;
			});
	}

	setIcon(id: string) {
		this.service.form.get('icon')?.setValue(id);
	}

	ngOnDestroy() {
		this.service.onClose();
	}
}

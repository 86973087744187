@if (data$ | async; as data) {
	@if (currentState) {
		<form [formGroup]="currentState">
			<section class="mb-12 flex flex-col gap-2" formGroupName="fences">
				<yuno-forms-title>Fences</yuno-forms-title>
				@for (item of currentState.controls.fences?.controls; track item; let i = $index) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="fence">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select fence..."
								[selectValues]="service.fenceValues"
								[display]="service.fenceDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-toggle
							labelPos="side"
							formControlName="active"
							label="Active"></yuno-forms-toggle>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('fences', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('fences')">
						Add Fence
					</button>
				</div>
			</section>
			<section class="mb-12 flex flex-col gap-2" formGroupName="layers">
				<yuno-forms-title>Layers</yuno-forms-title>
				@for (item of currentState.controls.layers?.controls; track item; let i = $index) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="layer">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select layer..."
								[selectValues]="service.layerValues"
								[display]="service.layerDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-select
							formControlName="visibility"
							class="flex-1"
							[selectValues]="['none', 'visible']"
							[display]="['Hidden', 'Visible']">
						</yuno-forms-select>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('layers', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('layers')">
						Add Layer
					</button>
				</div>
			</section>
			<section class="mb-12 flex flex-col gap-2" formGroupName="markers">
				<yuno-forms-title>Markers</yuno-forms-title>
				@for (item of currentState.controls.markers?.controls; track item; let i = $index) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="marker">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select marker..."
								[selectValues]="service.markerValues"
								[display]="service.markerDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-select
							formControlName="visibility"
							class="flex-1"
							[selectValues]="['none', 'visible']"
							[display]="['Hidden', 'Visible']">
						</yuno-forms-select>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('markers', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('markers')">
						Add Marker
					</button>
				</div>
			</section>
			<section class="mb-12 flex flex-col gap-2" formGroupName="markerCategories">
				<yuno-forms-title>Marker Categories</yuno-forms-title>
				@for (
					item of currentState.controls.markerCategories?.controls;
					track item;
					let i = $index
				) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="markerCategory">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select category..."
								[selectValues]="service.categoryValues"
								[display]="service.categoryDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-select
							formControlName="visibility"
							class="flex-1"
							[selectValues]="['none', 'visible']"
							[display]="['Hidden', 'Visible']">
						</yuno-forms-select>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('markerCategories', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('markerCategories')">
						Add Category
					</button>
				</div>
			</section>
			<section class="mb-12 flex flex-col gap-2" formGroupName="panoramas">
				<yuno-forms-title>Panoramas</yuno-forms-title>
				@for (
					item of currentState.controls.panoramas?.controls;
					track item;
					let i = $index
				) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="panorama">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select panorama..."
								[selectValues]="service.panoramaValues"
								[display]="service.panoramaDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-select
							formControlName="visibility"
							class="flex-1"
							[selectValues]="['none', 'visible']"
							[display]="['Hidden', 'Visible']">
						</yuno-forms-select>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('panoramas', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('panoramas')">
						Add Panorama
					</button>
				</div>
			</section>
			<section class="mb-12 flex flex-col gap-2" formGroupName="photoCategories">
				<yuno-forms-title>GeoPhotos</yuno-forms-title>
				@for (
					item of currentState.controls.photoCategories?.controls;
					track item;
					let i = $index
				) {
					<div
						class="flex w-full gap-8 rounded border border-gray-300 bg-gray-200 p-1"
						[formArrayName]="i">
						<ng-container formGroupName="category">
							<yuno-forms-select
								formControlName="_id"
								class="flex-1"
								placeholder="select photos..."
								[selectValues]="service.photoCategoriesValues"
								[display]="service.photoCategoriesDisplay">
							</yuno-forms-select>
						</ng-container>
						<yuno-forms-select
							formControlName="visibility"
							class="flex-1"
							[selectValues]="['none', 'visible']"
							[display]="['Hidden', 'Visible']">
						</yuno-forms-select>
						<div class="flex items-center">
							<button
								yuno-admin-delete-admin-button
								(click)="removeStateItem('photoCategories', i)">
								Remove
							</button>
						</div>
					</div>
				}
				<div class="flex">
					<button yuno-admin-add-button (click)="addStateItem('photoCategories')">
						Add Photos
					</button>
				</div>
			</section>
		</form>
	}
} @else {
	<div class="flex w-full flex-row justify-center">
		<yuno-spinner [spinnerSize]="30" [spinnerWidth]="2"></yuno-spinner>
	</div>
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LanguagePipe } from './language/language.pipe';
import { SafeHtmlIframePipe } from './safe-html/safe-html-iframe.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeIframePipe } from './safe-iframe.pipe';
import { ToRadiansPipe } from './to-radians.pipe';
import { UpperCaseFirstPipe } from './upper-case-first.pipe';

@NgModule({
	imports: [
		CommonModule,
		SafeHtmlPipe,
		SafeHtmlIframePipe,
		LanguagePipe,
		UpperCaseFirstPipe,
		SafeIframePipe,
		ToRadiansPipe
	],
	exports: [
		SafeHtmlPipe,
		SafeHtmlIframePipe,
		LanguagePipe,
		UpperCaseFirstPipe,
		SafeIframePipe,
		ToRadiansPipe
	]
})
export class AngularPipesModule {}

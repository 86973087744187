@if (data$ | async) {
	@if (data.title | languageSelect: language; as titleLanguage) {
		<span class="mb-4 text-xl font-semibold">{{ titleLanguage }}</span>
	} @else {
		<span class="mb-4 text-xl font-semibold">{{ title }}</span>
	}

	<ul class="w-62">
		@for (item of data.content; track trackByFn($index, item)) {
			<li>
				<yuno-legend-item [legend]="data" [language]="language" [data]="item" [map]="map" />
			</li>
		}
	</ul>
	<!-- Placeholder to correctly align with the external closing button -->
	<div class="absolute bottom-0 right-0 h-10 w-10"></div>
}

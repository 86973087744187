import {
	TextBlockComponent,
	TextButtonComponent,
	TextCollapsibleComponent,
	TextEmbedComponent,
	TextImageComponent,
	TextLinkComponent,
	TextListComponent,
	TextTogglesComponent,
	TextVideoComponent
} from '@yuno/angular/textfield';

import { CustomComponent, PageItemComponent } from '../../components';

export const PagesEditorComponentMap = {
	item: PageItemComponent,
	textblock: TextBlockComponent,
	button: TextButtonComponent,
	container: TextEmbedComponent,
	list: TextListComponent,
	link: TextLinkComponent,
	toggles: TextTogglesComponent,
	image: TextImageComponent,
	video: TextVideoComponent,
	custom: CustomComponent,
	header: undefined
};

export type PagesCompType =
	| typeof PageItemComponent
	| typeof CustomComponent
	| typeof TextBlockComponent
	| typeof TextButtonComponent
	| typeof TextCollapsibleComponent
	| typeof TextEmbedComponent
	| typeof TextImageComponent
	| typeof TextLinkComponent
	| typeof TextListComponent
	| typeof TextTogglesComponent
	| typeof TextVideoComponent;

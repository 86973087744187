@switch (list) {
	@case (false) {
		<yuno-legenda-item-content
			[sizing]="legend.size || 24"
			[language]="language"
			[data]="data"
			[map]="map" />
	}
	@case (true) {
		<yuno-legenda-item-content
			[sizing]="legend.size || 24"
			[language]="language"
			[data]="data"
			[list]="true"
			[map]="map"
			[layer]="data.layer" />
		@for (listItem of data.list; track listItem) {
			<yuno-legenda-item-content
				[sizing]="legend.size || 24"
				[language]="language"
				[map]="map"
				[data]="listItem"
				[parentClass]="data.class"
				[layer]="data.layer" />
		}
	}
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { geoPhotosGuard, geoPhotosListGuard } from './geo-photos.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [geoPhotosListGuard],
		loadComponent: () =>
			import('./internal-view.component').then(m => m.GeoPhotoViewSelectorComponent)
	},
	{
		path: ':id',
		canActivate: [geoPhotosGuard],
		loadChildren: () =>
			import('../view/view.router.module').then(m => m.GeoPhotoViewViewerRoutingModule)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GeoPhotoInternalViewRoutingModule {}

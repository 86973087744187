import { Routes } from '@angular/router';

import { DatasetEditorComponent } from '../editor/dataset-editor/dataset-editor.component';
import { DatasetChildRoutes } from '../editor/dataset-editor/dataset.editor.routing';
import { DatasetsComponent } from './datasets.component';

export const DatasetViewRoutes: Routes = [
	{
		path: '',
		loadComponent: () => DatasetsComponent,
		children: [
			{
				path: 'create',
				loadComponent: () => DatasetEditorComponent,
				loadChildren: () => DatasetChildRoutes
			},
			{
				path: 'edit/:id',
				loadComponent: () => DatasetEditorComponent,
				loadChildren: () => DatasetChildRoutes
			}
		]
	},
	{ path: '**', redirectTo: '' }
];

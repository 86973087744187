<div class="grid gap-8 lg:grid-cols-2">
	<form [formGroup]="service.appForm" class="mb-6 flex flex-col gap-6">
		<ng-container formGroupName="data">
			<section class="grid gap-4">
				<label class="yuno-form">
					<span class="select-none">Center Point</span>
					<div class="flex flex-row gap-4" formArrayName="coordinates">
						@for (coord of service.coordinates.controls; track coord; let i = $index) {
							<yuno-forms-number [step]="0.001" [formControlName]="i">
							</yuno-forms-number>
						}
					</div>
				</label>
			</section>
			<section>
				<h4>Extend</h4>
				<div formArrayName="extend" class="order-first grid gap-2">
					<div [formArrayName]="1" class="grid grid-cols-3 items-center justify-center">
						<yuno-forms-span
							class="col-span-3 col-start-3 mr-2 text-right font-semibold">
							top right
						</yuno-forms-span>
						<div class="col-span-3 col-start-1 gap-2">
							<div class="mb-1 flex items-center gap-2">
								<span>x:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="0"
									[step]="0.01" />
							</div>
							<div class="mb-1 flex items-center gap-2">
								<span>y:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="1"
									[step]="0.01" />
							</div>
						</div>
					</div>
					<div [formArrayName]="0" class="grid grid-cols-3 items-center justify-center">
						<yuno-forms-span class="col-span-3 font-semibold"
							>bottom left
						</yuno-forms-span>
						<div class="col-span-3 gap-2">
							<div class="mb-1 flex items-center gap-2">
								<span>x:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="0"
									[step]="0.01" />
							</div>
							<div class="mb-1 flex items-center gap-2">
								<span>y:</span>
								<yuno-forms-number
									class="w-full"
									[formControlName]="1"
									[step]="0.01" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</ng-container>
	</form>

	<yuno-admin-config-map
		(pointer)="setCoordinates($event)"
		(polygon)="setExtend($event)"
		[extend]="service.extend.value"
		[coordinates]="service.coordinates.value" />
</div>

import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
	imports: [TooltipComponent, OverlayModule, TooltipDirective],
	exports: [TooltipDirective]
})
export class TooltipModule {}

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { cookieFeature } from '@yuno/admin/features/privacy-and-cookies-editor/data-access/+state/cookies/cookies.state';
import { Cookie, CookieLanguage } from '@yuno/api/interface';

import { cookieActions } from './cookies.actions';

@Injectable()
export class CookiesFacade {
	cookies$ = this.store.pipe(select(cookieFeature.selectValues));
	selectedCookie$ = this.store.pipe(select(cookieFeature.selectSelected));

	cookieLanguages$ = this.store.pipe(select(cookieFeature.selectLanguageValues));
	selectedCookieLanguage$ = this.store.pipe(select(cookieFeature.selectSelectedLanguage));

	constructor(private readonly store: Store) {}

	getCookies(): void {
		this.store.dispatch(cookieActions.get());
	}

	getCookieLanguages(): void {
		this.store.dispatch(cookieActions.getLanguage());
	}

	selectCookie(id: string): void {
		this.store.dispatch(cookieActions.getById({ id }));
	}

	selectCookieLanguage(id: string): void {
		this.store.dispatch(cookieActions.getLanguageById({ id }));
	}

	clearSelectedCookie(): void {
		this.store.dispatch(cookieActions.resetSelected());
	}

	clearSelectedCookieLanguage(): void {
		this.store.dispatch(cookieActions.resetSelectedLanguage());
	}

	saveSelectedCookie(): void {
		this.store.dispatch(cookieActions.save());
	}

	saveSelectedCookieLanguage(): void {
		this.store.dispatch(cookieActions.saveLanguage());
	}

	updateSelectedCookie(data: Cookie): void {
		this.store.dispatch(cookieActions.update({ data }));
	}

	updateSelectedCookieLanguage(data: CookieLanguage): void {
		this.store.dispatch(cookieActions.updateLanguage({ data }));
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as variantsEffects from './+state/variants.effects';
import { VariantsFacade, variantsFeature } from './+state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(variantsFeature), provideEffects([variantsEffects]), VariantsFacade]
})
export class VariantsDataAccessModule {}

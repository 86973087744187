@if (user$ | async; as user) {
	<h1 class="font-semibold">Welcome {{ user.displayName }}</h1>
}
<span class="mb-4 font-light">
	Below are the Project Atlasses you have been assigned to. Hover over the items in the list to
	see the available options.
</span>
<yuno-admin-table
	[selectable]="false"
	[draggable]="false"
	[filterable]="true"
	[sortable]="true"
	[pagination]="true"
	[pageOptions]="{
		pageSize: 25,
		pageSizeOptions: [5, 10, 25, 100],
		hidePageSize: false
	}"
	[data]="data.apps"
	[buttons]="tableButtons"
	[columns]="[
		{ key: 'public', type: 'visible', disableVisibility: true },
		{ key: 'id', label: 'project' },
		{ key: 'client', child: 'id', label: 'client' },
		{ key: 'currentUserRole', label: 'role' }
	]"
	(clicked)="onSelectApp($event)">
	<button yuno-admin-button [add]="true" [routerLink]="['/', 'internal', 'create']">
		New Application
	</button>
</yuno-admin-table>

<ng-template #tableButtons let-row>
	@if (minimalReaderRole(row.currentUserRole)) {
		<button yuno-admin-button-table color="secondary" (click)="onSelectApp(row)">Edit</button>
	}
	<button yuno-admin-button-table color="primary" (click)="openApp(row)">Public</button>
	@if (atlasInternalRole(row.currentUserRole)) {
		<button yuno-admin-button-table color="success" (click)="openApp(row, true)">
			Internal
		</button>
	}
</ng-template>

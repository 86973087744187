import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent, DragRowComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { YunoButtonComponent } from '@yuno/project-atlas/ui';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		DragRowComponent,
		AnnotationComponent,
		YunoButtonComponent
	],
	selector: 'yuno-admin-atlas-help-settings',
	templateUrl: './atlas-help-settings.component.html',

	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasHelpSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
}

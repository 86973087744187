import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { App } from '@yuno/api/interface';

export const appResolver: ResolveFn<Partial<App> | undefined> = (): Observable<
	Partial<App> | undefined
> => {
	const store = inject(Store);
	return store.select(appFeature.selectApp);
};

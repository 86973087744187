import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppDataFacade, AppDataFeature } from '@yuno/admin/features/app-data';
import { AppData } from '@yuno/api/interface';

export const appDataResolver: ResolveFn<Partial<AppData> | undefined> = (): Observable<
	Partial<AppData> | undefined
> => {
	const store = inject(Store);
	const facade = inject(AppDataFacade);
	facade.checkProduction();
	return store.select(AppDataFeature.selectAppDataState);
};

import { Component } from '@angular/core';

@Component({
	selector: 'yuno-admin-dynamic-custom',
	template: ` <div class="m-0 rounded border border-teal-500 bg-teal-50 p-4">
		<span
			>This is a custom textfield and generates text/images/videos based on input data. No
			preview available. <br />
			<br />
			Only Administrators and Project Managers from XKP can alter these fields. Please contact
			them for more information.</span
		>
	</div>`,
	styleUrls: ['./custom.component.scss']
})
export class CustomComponent {}

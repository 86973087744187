import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as markersEffects from './+state/markers.effects';
import { MarkersFacade } from './+state/markers.facade';
import { markersFeature } from './+state/markers.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(markersFeature), provideEffects([markersEffects]), MarkersFacade]
})
export class MarkersDataAccessModule {}

export enum FileFormatTypes {
	excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	geojson = '.geojson',
	yuno = '.yuno',
	image = 'image/*, .dng',
	imageForceCamera = 'image/*,.dng,.pdf',
	pdf = 'application/pdf',
	file = 'application/pdf, image/*'
}

export interface DialogType {
	appId: string;
	type: FileFormatTypes;
	/**
	 * File Size Limit in MegaBytes
	 */
	filesizeLimit?: number;
	cdn?: boolean;
	patch?: string;
	delete?: string;
}

export interface FileData {
	fieldname: string;
	originalname: string;
	encoding: string;
	mimetype: string;
	id: string;
	filename: string;
	metadata: {
		projectId: string;
	};
	bucketName: string;
	chunkSize: number;
	size: number;
	md5: string;
	uploadDate: string;
	contentType: string;
}

@if (data) {
	@if (!list) {
		<!-- Image -->
		@if (!svg && !data.svg) {
			@if (data.image || type) {
				<div class="legenda-icon-width">
					<!-- Based on Type -->
					@if (!data.image && type) {
						<div
							class="legenda-icon {{ type }}"
							[style.backgroundColor]="color"
							[style.border]="border"
							[style.borderColor]="borderColor"></div>
					}
					<!-- Based on Image -->
					@if (data.image) {
						<img class="mt-2" [style]="getImageSize()" [src]="data.image" />
					}
				</div>
			}
		}

		<!-- SVG -->
		@if (!data.image && (data.svg || svg)) {
			<yuno-svg
				class="mt-1"
				[style]="getImageSize()"
				[src]="data.svg || svg"
				[fill]="data.color"
				[parentScale]="true" />
		}

		<!-- Gradiant -->
		@if (data.gradiant && data.gradiant.length >= 1) {
			<svg #svgGradiant xml:space="preserve" class="svg-gradiant"></svg>
		}
	}

	<!-- Title -->
	@if (data.title) {
		<span
			[ngClass]="{ 'span-both': spanBoth(), 'mt-4': list || data.bold }"
			[ngStyle]="{
				fontWeight: list || data.bold ? 600 : 400,
				'fontSize.em': list || data.bold ? 1.2 : 1
			}"
			[innerHtml]="data.title | languageSelect: language | safeHtml"></span>
	}
}

@if (data$ | async; as state) {
	@if (state.isOpen) {
		<div
			class="!pointer-events-none fixed z-20 flex h-full w-70 flex-col justify-end"
			[ngStyle]="{ 'bottom.px': offsetBottom, 'right.px': offsetRight }">
			<yuno-legend-container
				[language]="language"
				[maxHeight]="maxHeight"
				[isOpen]="state.isOpen || false"
				(isOpenChange)="close()"
				[title]="title"
				[data]="data"
				[map]="map" />
		</div>
	}
	@if (state.isOpen && !state.isClosing) {
		<div
			class="pointer-events-auto fixed z-100 flex h-10 w-10 cursor-pointer items-center justify-center"
			[@fadeIn]
			(click)="closeSlowly()"
			[ngStyle]="{ 'bottom.px': offsetBottom, 'right.px': offsetRight }">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="h-6 w-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
		</div>
	}
	<yuno-button
		(resized)="setButtonRect()"
		#button
		(click)="!state.isOpen && open()"
		[ngClass]="{ '!pointer-events-none': state.isOpen }"
		[borderColor]="state.isOpen ? 'rgba(0,0,0,0)' : ''"
		class="z-10">
		<span class="content-wrapper" #contentWrapper>
			<ng-content></ng-content>
		</span>
		<!-- if no ng-content -->
		<span class="fallback-text">Legend</span>
	</yuno-button>
}

import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { appDataActions } from './app-data.actions';
import { AppDataFeature } from './app-data.state';

@Injectable()
export class AppDataFacade {
	private readonly store = inject(Store);

	appData$ = this.store.pipe(select(AppDataFeature.selectAppDataState));
	cmsConfig$ = this.store.pipe(select(AppDataFeature.selectCmsConfig));

	checkProduction() {
		this.store.dispatch(appDataActions.checkProduction());
	}
}

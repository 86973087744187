import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as clientsEffects from './+state/clients.effects';
import { ClientsFacade } from './+state/clients.facade';
import { clientsFeature } from './+state/clients.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(clientsFeature), provideEffects([clientsEffects]), ClientsFacade]
})
export class ClientsDataAccessModule {}

import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { EventForm, EventFormsService, EventsForm } from '@yuno/admin/features/events';
import { TableRow } from '@yuno/admin/ui';
import {
	Dataset,
	DatasetDataFences,
	DatasetDataLayers,
	DatasetDataMarkerCategories,
	DatasetDataMarkers,
	DatasetDataObjects,
	DatasetDataPanoramas,
	DatasetDataParticipates,
	DatasetDataPhotoCategories,
	DatasetDataShapes,
	DatasetStates,
	VisibilityEnum
} from '@yuno/api/interface';

export interface DatasetForm {
	id: FormControl<string>;
	_id: FormControl<string>;
	// appId: FormControl<string>;
	data: FormGroup<DatasetDataForm>;
	states: FormArray<FormGroup<DatasetStatesForm>>;
}

export interface DatasetDataForm {
	fences?: FormArray<FormGroup<FenceDataForm>>;
	layers?: FormArray<FormGroup<LayerDataForm>>;
	markers?: FormArray<FormGroup<MarkerDataForm>>;
	markerCategories?: FormArray<FormGroup<CategoryDataForm>>;
	panoramas?: FormArray<FormGroup<PanoramaDataForm>>;
	objects?: FormArray<FormGroup<ObjectDataForm>>;
	participates?: FormArray<FormGroup<ParticipateDataForm>>;
	photoCategories?: FormArray<FormGroup<GeoPhotoDataForm>>;
	legend?: FormGroup<{
		_id: FormControl<string>;
	}>;
	shapes?: FormArray<FormGroup<ShapesDataForm>>;
	texts?: FormControl<string | null>;
}

export interface DatasetStatesForm {
	fences?: FormArray<FormGroup<FenceStateForm>>;
	layers?: FormArray<FormGroup<LayerStateForm>>;
	markers?: FormArray<FormGroup<MarkerStateForm>>;
	markerCategories?: FormArray<FormGroup<CategoryStateForm>>;
	panoramas?: FormArray<FormGroup<PanoramaStateForm>>;
	participates?: FormArray<FormGroup<ParticipateStateForm>>;
	photoCategories?: FormArray<FormGroup<GeoPhotosStateForm>>;
	shapes?: FormArray<FormGroup<ShapesStateForm>>;
	state?: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface FenceDataForm {
	active?: FormControl<boolean>;
	events: FormArray<FormGroup<EventForm>>;
	fence: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
}

interface LayerDataForm {
	events: FormGroup<EventsForm>;
	visibility?: FormControl<VisibilityEnum>;
	layer: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
}

interface MarkerDataForm {
	visibility?: FormControl<VisibilityEnum>;
	marker: FormGroup<{
		_id: FormControl<string>;
		properties: FormGroup<{
			id: FormControl<string>;
		}>;
	}>;
}

interface CategoryDataForm {
	category: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
	exclude?: FormArray<FormControl<string>>;
}

interface PanoramaDataForm {
	visibility?: FormControl<VisibilityEnum>;
	closeTextfield: FormControl<boolean>;
	panorama: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
	states: FormArray<FormGroup<PanoramaDataStatesForm>>;
}

export interface PanoramaDataStatesForm {
	state: FormGroup<{
		_id: FormControl<string>;
	}>;
	text: FormGroup<{
		_id: FormControl<string>;
	}>;
}

export interface ObjectDataForm {
	data: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
	type: FormControl<'data-threejs-pointcloud' | 'data-threejs-model' | 'data-shapefile'>;
}

interface ParticipateDataForm {
	visibility?: FormControl<VisibilityEnum>;
	participate: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
}

interface GeoPhotoDataForm {
	category: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
	exclude?: FormArray<FormControl<string>>;
}

export interface ShapesDataForm {
	canMove?: FormControl<boolean>;
	canScale?: FormControl<boolean>;
	canRotate?: FormControl<boolean>;
	fence: FormGroup<{
		_id: FormControl<string>;
		id: FormControl<string>;
	}>;
}

interface FenceStateForm {
	active: FormControl<boolean>;
	fence: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface LayerStateForm {
	visibility: FormControl<VisibilityEnum>;
	layer: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface MarkerStateForm {
	visibility: FormControl<VisibilityEnum>;
	marker: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface CategoryStateForm {
	visibility: FormControl<VisibilityEnum>;
	markerCategory: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface PanoramaStateForm {
	visibility: FormControl<VisibilityEnum>;
	panorama: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface ParticipateStateForm {
	visibility: FormControl<VisibilityEnum>;
	participate: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface GeoPhotosStateForm {
	visibility: FormControl<VisibilityEnum>;
	category: FormGroup<{
		_id: FormControl<string>;
	}>;
}

interface ShapesStateForm {
	active: FormControl<boolean>;
	fence: FormGroup<{
		_id: FormControl<string>;
	}>;
}

export type FunctionSelectors =
	| 'Fences'
	| 'Layers'
	| 'Markers'
	| 'Categories'
	| 'Panoramas'
	| 'Objects'
	| 'Participates'
	| 'GeoPhotos'
	| 'Shapes';

export type NameSelectors =
	| 'fence'
	| 'layer'
	| 'marker'
	| 'category'
	| 'panorama'
	| 'data'
	| 'participate';

export type StateSelectors =
	| 'fences'
	| 'layers'
	| 'markers'
	| 'markerCategories'
	| 'panoramas'
	| 'photoCategories';

@Injectable({
	providedIn: 'root'
})
export class DatasetEditorService {
	private readonly eventsForm = inject(EventFormsService);

	destroyed$: Subject<boolean> = new Subject();
	active$ = new BehaviorSubject<boolean>(false);

	form: FormGroup<DatasetForm>;

	legendValues: string[] = [''];
	legendDisplay: string[] = ['none'];

	stateValues: string[] = [];
	stateDisplay: string[] = [];

	fenceValues: string[] = [];
	fenceDisplay: string[] = [];

	layerValues: string[] = [];
	layerDisplay: string[] = [];

	markerValues: string[] = [];
	markerDisplay: string[] = [];

	categoryValues: string[] = [];
	categoryDisplay: string[] = [];

	panoramaValues: string[] = [];
	panoramaDisplay: string[] = [];

	photoCategoriesValues: string[] = [];
	photoCategoriesDisplay: string[] = [];

	eventIndex = 0;

	rawForm$ = new ReplaySubject<Partial<Dataset>>(1);
	customStates$ = new ReplaySubject<boolean>(1);

	get data(): FormGroup<DatasetDataForm> {
		return this.form.get('data') as FormGroup<DatasetDataForm>;
	}

	get dataFences(): FormArray<FormGroup<FenceDataForm>> {
		return this.data.get('fences') as FormArray<FormGroup<FenceDataForm>>;
	}

	get dataLayers(): FormArray<FormGroup<LayerDataForm>> {
		return this.data.get('layers') as FormArray<FormGroup<LayerDataForm>>;
	}

	get dataMarkers(): FormArray<FormGroup<MarkerDataForm>> {
		return this.data.get('markers') as FormArray<FormGroup<MarkerDataForm>>;
	}

	get dataCategories(): FormArray<FormGroup<CategoryDataForm>> {
		return this.data.get('markerCategories') as FormArray<FormGroup<CategoryDataForm>>;
	}

	get dataPanoramas(): FormArray<FormGroup<PanoramaDataForm>> {
		return this.data.get('panoramas') as FormArray<FormGroup<PanoramaDataForm>>;
	}

	get dataParticipates(): FormArray<FormGroup<ParticipateDataForm>> {
		return this.data.get('participates') as FormArray<FormGroup<ParticipateDataForm>>;
	}

	get dataObjects(): FormArray<FormGroup<ObjectDataForm>> {
		return this.data.get('objects') as FormArray<FormGroup<ObjectDataForm>>;
	}

	get dataGeoPhotos(): FormArray<FormGroup<GeoPhotoDataForm>> {
		return this.data.get('photoCategories') as FormArray<FormGroup<GeoPhotoDataForm>>;
	}

	get dataShapes(): FormArray<FormGroup<ShapesDataForm>> {
		return this.data.get('shapes') as FormArray<FormGroup<ShapesDataForm>>;
	}

	get states(): FormArray {
		return (
			(this.form.get('states') as FormArray) ||
			new FormArray<FormGroup<DatasetStatesForm>>([])
		);
	}

	createFormGroup(): void {
		if (this.form) {
			this.form.reset();
		}

		this.form = new FormGroup<DatasetForm>({
			_id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			// appId: new FormControl('', { nonNullable: true }),
			data: new FormGroup<DatasetDataForm>({
				fences: new FormArray<FormGroup<FenceDataForm>>([]),
				layers: new FormArray<FormGroup<LayerDataForm>>([]),
				markers: new FormArray<FormGroup<MarkerDataForm>>([]),
				markerCategories: new FormArray<FormGroup<CategoryDataForm>>([]),
				panoramas: new FormArray<FormGroup<PanoramaDataForm>>([]),
				objects: new FormArray<FormGroup<ObjectDataForm>>([]),
				participates: new FormArray<FormGroup<ParticipateDataForm>>([]),
				photoCategories: new FormArray<FormGroup<GeoPhotoDataForm>>([]),
				legend: new FormGroup({
					_id: new FormControl('', { nonNullable: true })
				}),
				shapes: new FormArray<FormGroup<ShapesDataForm>>([])
			}),
			states: new FormArray<FormGroup<DatasetStatesForm>>([])
		});
	}

	addFences(data: DatasetDataFences[]): void {
		for (const item of data) {
			this.dataFences.push(this.createFencesForm(item));
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.dataFences.patchValue(data as any);
	}

	addLayers(data: DatasetDataLayers[]): void {
		for (const item of data) {
			this.dataLayers.push(this.createLayersForm(item));
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.dataLayers.patchValue(data as any);
	}

	addMarkers(data: DatasetDataMarkers[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataMarkers.push(this.createMarkersForm());
		}
	}

	addMarkerCategories(data: DatasetDataMarkerCategories[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataCategories.push(this.createCategoriesForm());
		}

		this.dataCategories.patchValue(data);
	}

	addPanoramas(data: DatasetDataPanoramas[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataPanoramas.push(this.createPanoramasForm(item));
		}

		this.dataPanoramas.patchValue(data);
	}

	addParticipates(data: DatasetDataParticipates[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataParticipates.push(this.createParticipatesForm());
		}

		const patchedValue = data.map(data => {
			return { participate: { _id: data.participate, id: '' } };
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.dataParticipates.patchValue(patchedValue as any);
	}

	addGeoPhotos(data: DatasetDataPhotoCategories[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataGeoPhotos.push(this.createGeoPhotosForm());
		}

		const patchedValue = data.map(data => {
			return { category: { _id: data.category, id: '' } };
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.dataGeoPhotos.patchValue(patchedValue as any);
	}

	addObjects(data: DatasetDataObjects[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.dataObjects.push(this.createObjectsForm());
		}

		this.dataObjects.patchValue(data);
	}

	addShapes(data: DatasetDataShapes[]): void {
		for (const item of data) {
			this.dataShapes.push(this.createShapesForm());
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.dataShapes.patchValue(data as any);
	}

	createFencesForm(item?: DatasetDataFences): FormGroup<FenceDataForm> {
		const form = new FormGroup<FenceDataForm>({
			events: this.eventsForm.createEventForm(),
			fence: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			})
		});

		if (item) {
			const events = (item as DatasetDataFences).events;
			if (events && events.length >= 1) {
				events.forEach(event => {
					form.controls.events.push(this.eventsForm.createEvent(event.type));
				});
			}
		}

		return form;
	}

	private createLayersForm(item?: DatasetDataLayers): FormGroup<LayerDataForm> {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const form = new FormGroup<LayerDataForm>({
			events: this.eventsForm.createEventsForm(),
			layer: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			})
		});

		if (item) {
			const events = (item as DatasetDataLayers).events;
			if (events?.onClick && events.onClick.length >= 1) {
				events.onClick.forEach(event => {
					form.controls.events.controls.onClick.push(
						this.eventsForm.createEvent(event.type, event.options)
					);
				});
			}

			if (events?.onMouseMove && events.onMouseMove.length >= 1) {
				events.onMouseMove.forEach(event => {
					form.controls.events.controls.onMouseMove.push(
						this.eventsForm.createEvent(event.type, event.options)
					);
				});
			}

			if (events?.onMouseLeave && events.onMouseLeave.length >= 1) {
				events.onMouseLeave.forEach(event => {
					form.controls.events.controls.onMouseLeave.push(
						this.eventsForm.createEvent(event.type, event.options)
					);
				});
			}
		}
		return form;
	}

	private createMarkersForm(): FormGroup<MarkerDataForm> {
		return new FormGroup<MarkerDataForm>({
			marker: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				properties: new FormGroup({
					id: new FormControl('', { nonNullable: true })
				})
			})
		});
	}

	private createCategoriesForm(): FormGroup<CategoryDataForm> {
		return new FormGroup<CategoryDataForm>({
			category: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			}),
			exclude: new FormArray<FormControl<string>>([])
		});
	}

	private createGeoPhotosForm(): FormGroup<GeoPhotoDataForm> {
		return new FormGroup<GeoPhotoDataForm>({
			category: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			}),
			exclude: new FormArray<FormControl<string>>([])
		});
	}

	private createPanoramasForm(item?: DatasetDataPanoramas): FormGroup<PanoramaDataForm> {
		const form = new FormGroup<PanoramaDataForm>({
			closeTextfield: new FormControl(false, { nonNullable: true }),
			panorama: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			}),
			states: new FormArray<FormGroup<PanoramaDataStatesForm>>([])
		});

		if (item) {
			const states = (item as DatasetDataPanoramas).states;
			if (!states || states.length < 1) {
				return form;
			}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const state of states) {
				form.controls.states.push(this.createCustomState());
			}

			form.controls.states.patchValue(states);
		}

		return form;
	}

	private createParticipatesForm(): FormGroup<ParticipateDataForm> {
		return new FormGroup<ParticipateDataForm>({
			participate: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createObjectsForm(): FormGroup<ObjectDataForm> {
		return new FormGroup<ObjectDataForm>({
			data: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			}),
			type: new FormControl('data-shapefile', {
				nonNullable: true,
				validators: Validators.required
			})
		});
	}

	private createShapesForm(): FormGroup<ShapesDataForm> {
		return new FormGroup<ShapesDataForm>({
			canMove: new FormControl(false, { nonNullable: true }),
			canScale: new FormControl(false, { nonNullable: true }),
			canRotate: new FormControl(false, { nonNullable: true }),
			fence: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateFenceForm(): FormGroup<FenceStateForm> {
		return new FormGroup<FenceStateForm>({
			active: new FormControl(false, { nonNullable: true }),
			fence: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateLayerForm(): FormGroup<LayerStateForm> {
		return new FormGroup<LayerStateForm>({
			visibility: new FormControl('visible' as VisibilityEnum, {
				nonNullable: true
			}),
			layer: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateMarkerForm(): FormGroup<MarkerStateForm> {
		return new FormGroup<MarkerStateForm>({
			visibility: new FormControl('visible' as VisibilityEnum, {
				nonNullable: true
			}),
			marker: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateMarkerCategoryForm(): FormGroup<CategoryStateForm> {
		return new FormGroup<CategoryStateForm>({
			visibility: new FormControl('visible' as VisibilityEnum, {
				nonNullable: true
			}),
			markerCategory: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStatePanoramaForm(): FormGroup<PanoramaStateForm> {
		return new FormGroup<PanoramaStateForm>({
			visibility: new FormControl('visible' as VisibilityEnum, {
				nonNullable: true
			}),
			panorama: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateGeoPhotosForm(): FormGroup<GeoPhotosStateForm> {
		return new FormGroup<GeoPhotosStateForm>({
			visibility: new FormControl('visible' as VisibilityEnum, {
				nonNullable: true
			}),
			category: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStateShapesForm(): FormGroup<ShapesStateForm> {
		return new FormGroup<ShapesStateForm>({
			active: new FormControl(false, { nonNullable: true }),
			fence: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	private createStatesForm(item?: DatasetStates): FormGroup<DatasetStatesForm> {
		const form = new FormGroup<DatasetStatesForm>({
			fences: new FormArray<FormGroup<FenceStateForm>>([]),
			layers: new FormArray<FormGroup<LayerStateForm>>([]),
			markers: new FormArray<FormGroup<MarkerStateForm>>([]),
			markerCategories: new FormArray<FormGroup<CategoryStateForm>>([]),
			panoramas: new FormArray<FormGroup<PanoramaStateForm>>([]),
			participates: new FormArray<FormGroup<ParticipateStateForm>>([]),
			photoCategories: new FormArray<FormGroup<GeoPhotosStateForm>>([]),
			shapes: new FormArray<FormGroup<ShapesStateForm>>([]),
			state: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});

		if (item) {
			if (item.fences && item.fences.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.fences) {
					form.controls?.fences?.push(this.createStateFenceForm());
				}
			}
			if (item.layers && item.layers.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.layers) {
					form.controls?.layers?.push(this.createStateLayerForm());
				}
			}
			if (item.markers && item.markers.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.markers) {
					form.controls?.markers?.push(this.createStateMarkerForm());
				}
			}
			if (item.markerCategories && item.markerCategories.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.markerCategories) {
					form.controls?.markerCategories?.push(this.createStateMarkerCategoryForm());
				}
			}
			if (item.panoramas && item.panoramas.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.panoramas) {
					form.controls?.panoramas?.push(this.createStatePanoramaForm());
				}
			}
			if (item.photoCategories && item.photoCategories.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.photoCategories) {
					form.controls?.photoCategories?.push(this.createStateGeoPhotosForm());
				}
			}
			if (item.shapes && item.shapes.length) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const data of item.shapes) {
					form.controls?.shapes?.push(this.createStateShapesForm());
				}
			}
		}

		return form;
	}

	add(name: FunctionSelectors, data: { [key: string]: unknown }): void {
		let form: FormGroup;

		if (name === 'Fences') {
			form = this[`create${name}Form`](data);
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Layers') {
			form = this[`create${name}Form`](data);
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Markers') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Categories') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Panoramas') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Objects') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Participates') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'GeoPhotos') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
		if (name === 'Shapes') {
			form = this[`create${name}Form`]();
			form.patchValue(data);
			this[`data${name}`].push(form);
		}
	}

	addStateItem(type: StateSelectors, index: number): void {
		const mainForm = this.states.controls[index] as FormGroup<DatasetStatesForm>;
		if (!mainForm) {
			return;
		}

		if (type === 'fences') {
			mainForm.controls.fences?.push(this.createStateFenceForm());
		}

		if (type === 'layers') {
			mainForm.controls.layers?.push(this.createStateLayerForm());
		}

		if (type === 'markers') {
			mainForm.controls.markers?.push(this.createStateMarkerForm());
		}

		if (type === 'markerCategories') {
			mainForm.controls.markerCategories?.push(this.createStateMarkerCategoryForm());
		}

		if (type === 'panoramas') {
			mainForm.controls.panoramas?.push(this.createStatePanoramaForm());
		}

		if (type === 'photoCategories') {
			mainForm.controls.photoCategories?.push(this.createStateGeoPhotosForm());
		}
	}

	removeStateItem(type: StateSelectors, stateIndex: number, i: number): void {
		const mainForm = this.states.controls[stateIndex] as FormGroup<DatasetStatesForm>;
		if (!mainForm) {
			return;
		}

		if (type === 'fences') {
			mainForm.controls.fences?.removeAt(i);
		}

		if (type === 'layers') {
			mainForm.controls.layers?.removeAt(i);
		}

		if (type === 'markers') {
			mainForm.controls.markers?.removeAt(i);
		}

		if (type === 'markerCategories') {
			mainForm.controls.markerCategories?.removeAt(i);
		}

		if (type === 'panoramas') {
			mainForm.controls.panoramas?.removeAt(i);
		}

		if (type === 'photoCategories') {
			mainForm.controls.photoCategories?.removeAt(i);
		}
	}

	remove(name: FunctionSelectors, control: NameSelectors, data: TableRow): void {
		const formGroups = this[`data${name}`].controls as FormGroup[];
		const foundIndex = formGroups.findIndex(
			group => group.controls[control].get('_id')?.value === data['_id']
		);

		if (foundIndex < 0) {
			return;
		}

		this[`data${name}`].removeAt(foundIndex);
	}

	selectAll(name: FunctionSelectors, data: { [key: string]: Partial<unknown> }[]): void {
		if (data.length < 1) {
			this[`data${name}`].clear();
			return;
		}

		const count = data.length - this[`data${name}`].value.length;
		for (let i = 0; i < count; i++) {
			if (name === 'Fences') {
				this[`data${name}`].push(this[`create${name}Form`](data[i]));
				continue;
			}
			if (name === 'Layers') {
				this[`data${name}`].push(this[`create${name}Form`](data[i]));
				continue;
			}
			if (name === 'Markers') {
				this[`data${name}`].push(this[`create${name}Form`]());
				continue;
			}
			if (name === 'Categories') {
				this[`data${name}`].push(this[`create${name}Form`]());
				continue;
			}
			if (name === 'Panoramas') {
				this[`data${name}`].push(this[`create${name}Form`]());
				continue;
			}
			if (name === 'Objects') {
				this[`data${name}`].push(this[`create${name}Form`]());
				continue;
			}
			if (name === 'Participates') {
				this[`data${name}`].push(this[`create${name}Form`]());
			}
			if (name === 'GeoPhotos') {
				this[`data${name}`].push(this[`create${name}Form`]());
			}
		}

		this[`data${name}`].patchValue(data);
	}

	createCustomState(): FormGroup<PanoramaDataStatesForm> {
		return new FormGroup<PanoramaDataStatesForm>({
			state: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			}),
			text: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});
	}

	addCustomState(): void {
		if (this.eventIndex < 0) {
			return;
		}

		const currentForm = this.dataPanoramas?.controls[this.eventIndex].get(
			'states'
		) as FormArray<FormGroup<PanoramaDataStatesForm>>;
		currentForm.push(this.createCustomState());
	}

	/* STATES */

	removeState(index: number): void {
		this.states.removeAt(index);
	}

	addState(): void {
		this.states.push(this.createStatesForm());
	}

	addStates(data: Dataset['states']): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const item of data) {
			this.states.push(this.createStatesForm(item));
		}

		this.states.patchValue(data);
	}
}

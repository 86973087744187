import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { PrivacyStatement } from '@yuno/api/interface';

import { privacyStatementActions } from './privacy-statement-xkp.actions';
import { privacyFeature } from './privacy-statement-xkp.state';

@Injectable()
export class PrivacyFacade {
	private readonly store = inject(Store);

	values$ = this.store.pipe(select(privacyFeature.selectValues));
	selected$ = this.store.pipe(select(privacyFeature.selectSelected));

	get(): void {
		this.store.dispatch(privacyStatementActions.values());
	}

	clear(): void {
		this.store.dispatch(privacyStatementActions.clear());
	}

	clearSelected(): void {
		this.store.dispatch(privacyStatementActions.clearSelected());
	}

	select(id: string): void {
		this.store.dispatch(privacyStatementActions.value({ id }));
	}

	patch(data: Partial<PrivacyStatement>): void {
		this.store.dispatch(privacyStatementActions.patchValue({ data }));
	}

	save(): void {
		this.store.dispatch(privacyStatementActions.saveValue());
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as templatesEffects from './+state/templates.effects';
import { TemplatesFacade } from './+state/templates.facade';
import { templatesFeature } from './+state/templates.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(templatesFeature), provideEffects([templatesEffects]), TemplatesFacade]
})
export class TemplatesDataAccessModule {}

import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { tap } from 'rxjs';

import { LanguagePipe, SafeIframePipe, iframeCookieMessage } from '@yuno/angular/pipes';

import { EventsService } from '../../services/events.service';
import { TextNoCookieComponent } from '../text-no-cookie/text-no-cookie.component';

@Component({
	selector: 'yuno-text-iframe[src]',
	// providers: [provideTranslocoScope('textfields')],
	template: `
		@if ({ show: showCookieMessage | async }; as data) {
			<ng-template [ngTemplateOutlet]="data.show ? iframe : body"></ng-template>
		}
		<ng-template #iframe>
			<!-- not using the aspect-video because older ios systems are not capable  -->
			<div
				#container
				class="yuno-text-iframe aspect-h-9 aspect-w-16 overflow-hidden bg-gray-600">
				<iframe [src]="src | safeIframe"></iframe>
			</div>

			<!-- Description -->
			@if (description) {
				<span class="text-sm">
					<ng-content></ng-content>
					{{ description | languageSelect: language }}
				</span>
			}
		</ng-template>

		<ng-template #nocookie>
			<yuno-text-no-cookie></yuno-text-no-cookie>
		</ng-template>
	`,
	styleUrls: ['./text-iframe.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, TextNoCookieComponent, AsyncPipe, LanguagePipe, SafeIframePipe]
})
export class TextIframeComponent {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;
	private service = inject(EventsService);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ViewChild('iframe', { static: true }) iframeTemplate: TemplateRef<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ViewChild('nocookie', { static: true }) nocookieTemplate: TemplateRef<any>;

	private _src: string;
	@Input() set src(src: string) {
		this._src = src;
		this.checkIframeCookie();
	}
	get src(): string {
		return this._src;
	}

	@Input() language?: string = 'nl';
	@Input() description?: string;

	body: TemplateRef<ElementRef>;
	showCookieMessage = this.service.acceptThirdParty$.pipe(
		tap(bool => {
			bool && this.checkIframeCookie();
		})
	);

	checkIframeCookie(): void {
		this.body = iframeCookieMessage.some(sub => this.src.includes(sub))
			? this.nocookieTemplate
			: this.iframeTemplate;
	}
}

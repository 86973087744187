<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-4">
	<section formGroupName="help">
		<yuno-forms-toggle formControlName="active" label="Active"></yuno-forms-toggle>
	</section>

	<section [formGroup]="service.helpDisplay">
		@for (lang of service.languageSelect; track lang) {
			@if (lang === this.language) {
				<yuno-forms-text [formControlName]="lang" label="Display" />
			}
		}
	</section>
	<section [formGroup]="service.helpTextfields" class="grid grid-cols-2 gap-4">
		<yuno-forms-select
			formControlName="desktop"
			label="Desktop"
			[selectValues]="service.defaultHelpTextSelect"
			[display]="service.defaultHelpTextDisplay"></yuno-forms-select>
		<yuno-forms-select
			formControlName="mobile"
			label="Mobile"
			[selectValues]="service.defaultHelpMobileSelect"
			[display]="service.defaultHelpMobileDisplay"></yuno-forms-select>
	</section>
	<section formGroupName="help" class="grid grid-cols-2 gap-4">
		<yuno-forms-select
			formControlName="target"
			label="Target (choose where the textfield is displayed)"
			[selectValues]="service.targetValues"
			[display]="service.targetValues"></yuno-forms-select>
	</section>
</form>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularPipesModule } from '@yuno/angular/pipes';

import { ButtonCardComponent } from './button-card.component';

@NgModule({
	declarations: [ButtonCardComponent],
	imports: [CommonModule, AngularPipesModule],
	exports: [ButtonCardComponent]
})
export class ButtonCardModule {}

import { IOptions, defaults } from 'sanitize-html';

import { svgAttributes, svgElements } from './helpers.svg';
import { textfieldElements } from './helpers.textfield';

/* Create Object allows Tags with SvgAttributes */
const svgTagsAllowed = svgElements.reduce((acc, curr) => ({ ...acc, [curr]: svgAttributes }), {});
const textfieldTagsAllowed = textfieldElements.reduce(
	(acc, curr) => ({ ...acc, [curr]: ['*'] }),
	{}
);

export const safeHtmlOptions: IOptions = {
	allowedTags: defaults.allowedTags.concat([
		'img',
		'svg',
		'path',
		'defs',
		'linearGradient',
		'rect',
		'polygon',
		'circle',
		'video',
		'picture',
		'source',
		...textfieldElements,
		...svgElements,
		...textfieldElements,
		...svgElements
	]),
	allowedAttributes: {
		'*': ['style'],
		a: ['href', 'name', 'target'],
		img: ['src'],
		iframe: ['src'],
		path: ['*'],
		image: ['*'],
		table: [
			'align',
			'border',
			'bgcolor',
			'cellpadding',
			'cellspacing',
			'frame',
			'rules',
			'summary',
			'width'
		],
		source: ['src', 'srcset', 'type'],
		video: ['controls', 'controlslist', 'preload', 'data-videoid', 'poster'],
		th: ['colspan', 'rowspan', 'headers', 'scope', 'abbr'],
		td: ['colspan', 'rowspan', 'headers'],
		...svgTagsAllowed,
		...textfieldTagsAllowed
	},
	allowedClasses: {
		'*': ['*']
	},
	selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
	allowedSchemes: ['http', 'https', 'mailto', 'tel'],
	allowedSchemesByTag: {},
	allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
	allowProtocolRelative: true,
	enforceHtmlBoundary: false,
	parser: {
		lowerCaseAttributeNames: false
	}
};

export const safeHtmlIframesOptions: IOptions = {
	...safeHtmlOptions,
	allowedTags: defaults.allowedTags.concat([
		'iframe',
		...(safeHtmlOptions.allowedTags as string[]),
		...textfieldElements,
		...svgElements
	]),
	allowedIframeHostnames: [
		'www.youtube.com',
		'www.youtube-nocookie.com',
		'youtu.be',
		'player.vimeo.com',
		'projectatlas.app',
		'docs.google.com',
		'drive.google.com'
	]
};

export const iframeCookieMessage = ['www.youtube.com', 'www.youtube-nocookie.com', 'youtu.be'];

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';

import { MapMarkerData, MarkerEvent } from '@yuno/api/interface';

@Component({
	selector: 'yuno-marker-default',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultComponent {
	protected _data: MapMarkerData | null;
	hasEvents = false;
	hasClickEvents = false;

	@HostBinding('class')
	get elementClasses() {
		return {
			'!cursor-move': this.draggable,
			'cursor-pointer': this.hasEvents || this.placeMarker,
			'z-10': this.hasEvents || this.placeMarker
		};
	}

	_draggable = false;
	@Input() set draggable(bool: boolean) {
		this._draggable = bool;
	}

	get draggable(): boolean {
		return this._draggable;
	}

	@Input() set data(data: MapMarkerData | null) {
		this._data = data;
		this.setEvents();
	}

	get data() {
		return this._data;
	}

	@Input() placeMarker = false;

	@Output() clickEvent = new EventEmitter<MarkerEvent>();
	@Output() moveEvent = new EventEmitter<MarkerEvent>();

	onClick(event: MouseEvent): void {
		if ((this.data?.events?.onClick && this.data?.events?.onClick.length) || this.placeMarker) {
			this.clickEvent.emit({
				mouseEvent: event,
				events: this.data?.events?.onClick || []
			});
		}
	}

	onMove(event: MouseEvent, bool: boolean): void {
		if (bool && this.data?.events?.onMouseMove && this.data?.events?.onMouseMove.length) {
			return this.moveEvent.emit({
				mouseEvent: event,
				events: this.data.events.onMouseMove
			});
		}

		this.moveEvent.emit({ mouseEvent: event });
	}

	setEvents(): void {
		if (this.data?.events?.onClick && this.data.events.onClick.length >= 1) {
			this.hasEvents = true;
			this.hasClickEvents = true;
			return;
		}

		if (this.data?.events?.onMouseMove && this.data.events.onMouseMove.length >= 1) {
			this.hasEvents = true;
		}
	}
}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, map, take } from 'rxjs';

import { GET_APPS_CLIENT, GET_APPS_INTERNAL } from '@yuno/admin/core/graphql/app';
import { GraphQLService } from '@yuno/angular-graphql';
import { App } from '@yuno/api/interface';

export const AppsResolver: ResolveFn<Partial<App>[] | undefined> = (
	route,
	state
): Observable<Partial<App>[] | undefined> => {
	const graphql = inject(GraphQLService);
	return graphql
		.query<{ apps: Partial<App>[] }>({
			query: state.url.includes('clients') ? GET_APPS_CLIENT : GET_APPS_INTERNAL
		})
		.pipe(
			map(app => {
				return app.data.apps;
			}),
			take(1)
		);
};

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { AuthFacade } from '@yuno/angular-auth';

@Component({
	selector: 'yuno-sidebar-user',
	templateUrl: './sidebar-user.component.html',
	styleUrls: ['./sidebar-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarUserComponent {
	private readonly authFacade = inject(AuthFacade);

	user$ = this.authFacade.user$;
	appRole$ = this.authFacade.appRole$;

	onLogout() {
		this.authFacade.logout();
	}
}

import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AuthFacade } from '@yuno/angular-auth';

import { ClientRoute } from './redirect.guard';

export const XkpGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
	const authFacade = inject(AuthFacade);
	const router = inject(Router);

	return authFacade.user$.pipe(
		map(user => {
			if (user?.role === 'appuser') {
				return router.createUrlTree([ClientRoute]);
			}

			return true;
		})
	);
};

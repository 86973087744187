import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutofocusDirective } from './autofocus.directive';
import { IfChangesDirective } from './ifchanges.directive';
import { ResizedDirective } from './resized.directive';

@NgModule({
	imports: [CommonModule, ResizedDirective, IfChangesDirective, AutofocusDirective],
	exports: [ResizedDirective, IfChangesDirective, AutofocusDirective]
})
export class YunoDirectivesModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as modelsEffects from './+state/models/models.effects';
import * as notificationsEffects from './+state/notifications/notifications.effects';
import * as participationsEffects from './+state/participations/participations.effects';
import {
	ParticipationFacade,
	ParticipationModelFacade,
	ParticipationNotificationFacade,
	modelsFeature,
	notificationsFeature,
	participationsFeature
} from './+state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(modelsFeature),
		provideState(participationsFeature),
		provideState(notificationsFeature),
		provideEffects([modelsEffects, participationsEffects, notificationsEffects]),
		ParticipationFacade,
		ParticipationModelFacade,
		ParticipationNotificationFacade
	]
})
export class ParticipationDataAccessModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'UpperCaseFirst',
	standalone: true
})
export class UpperCaseFirstPipe implements PipeTransform {
	transform(value: string): SafeHtml {
		if (!value) {
			return value;
		}
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
}

import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, tap } from 'rxjs';

import { ClientsFacade } from '@yuno/admin/features/clients';
import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent,
	YunoAdminNavbarButtonsComponent
} from '@yuno/admin/ui';
import { GraphqlAngularModule } from '@yuno/angular-graphql';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';

import { ConfigMapComponent } from '../../../../../app-configuration/src/lib/feature/settings/config-map/config-map.component';
import { CreateAppService } from './create-app.service';

@Component({
	selector: 'yuno-admin-create-app',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		DragDropModule,
		GraphqlAngularModule,
		YunoFormsModule,
		YunoAdminNavbarButtonsComponent,
		YunoAdminButtonComponent,
		DragRowComponent,
		AsyncPipe,
		ConfigMapComponent,
		YunoAdminButtonTableComponent
	],
	providers: [CreateAppService],
	templateUrl: './create-app.component.html',
	styleUrls: ['./create-app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAppComponent implements OnInit {
	readonly service = inject(CreateAppService);
	readonly clients = inject(ClientsFacade);

	data$ = combineLatest({
		selectLoaded: this.clients.clients$.pipe(
			tap(clients => {
				if (!clients) {
					return;
				}

				this.service.clientsDisplay = clients.map(client => client.id as string);
				this.service.clientsSelect = clients.map(client => client._id as string);
			})
		)
	});

	ngOnInit(): void {
		this.clients.loadClients();
		this.service.createFormGroup();
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInFormArray(this.service.urls, event.previousIndex, event.currentIndex);
	}

	onAdd(): void {
		this.service.addUrl();
	}

	onRemove(index: number): void {
		this.service.removeUrl(index);
	}

	create(): void {
		this.service.onSubmit();
	}
}

@if (user$ | async; as user) {
	<div class="flex h-16 items-center justify-start gap-4">
		<div class="flex h-7 w-7 items-center justify-center rounded-3xl bg-gray-100 p-1.5">
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15">
				<path
					data-name="user_icon"
					d="M7 6a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm-7 9a7 7 0 0 1 14 0Z"
					fill="#394558"
					fill-rule="evenodd" />
			</svg>
		</div>
		@if (appRole$ | async; as role) {
			@if (role === 'atlas') {
				<button class="text-yuno-blue hover:cursor-pointer hover:underline">
					{{ user.email }}
				</button>
			}
			@if (role !== 'atlas') {
				<div class="flex flex-col">
					<button class="text-yuno-blue hover:cursor-pointer hover:underline">
						{{ user.email }}
					</button>
					<span class="text-sm text-gray-500">{{ role }} </span>
				</div>
			}
		}
	</div>
}

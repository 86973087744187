import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Position } from 'geojson';

import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { InternalViewService } from '../../internal-view/internal-view.service';
import { ConfigMapComponent } from '../config-map/config-map.component';

@Component({
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, ConfigMapComponent],
	selector: 'yuno-admin-project-location-settings',
	templateUrl: './project-location-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfigurationProjectSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);

	setCoordinates(coordinates: Position) {
		this.service.onUpdateCoordinates(coordinates);
	}

	setExtend(polygon: Position[] | null) {
		if (polygon) {
			this.service.onUpdateExtend(polygon);
		}
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as SourcesCustomEffects from './+state/sources-custom/sources-custom.effects';
import * as SourcesEffects from './+state/sources/sources.effects';
import { SourcesFacade, sourcesFeature } from './+state/sources';
import { SourcesCustomFacade, sourcesCustomFeature } from './+state/sources-custom';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(sourcesFeature),
		provideState(sourcesCustomFeature),
		provideEffects([SourcesEffects, SourcesCustomEffects]),
		SourcesFacade,
		SourcesCustomFacade
	]
})
export class SourcesDataAccessModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import {
	ImageFacade,
	NotificationFacade,
	PagesFacade,
	TextButtonFacade,
	TextCustomFacade,
	TextLinkFacade,
	TextListFacade,
	TextNewsItemFacade,
	TextToggleFacade,
	TextblockFacade,
	TextfieldFacade,
	VideoFacade,
	imagesFeature,
	notificationFeature,
	pagesFeature,
	textButtonsFeature,
	textCustomsFeature,
	textLinksFeature,
	textListsFeature,
	textNewsItemsFeature,
	textTogglesFeature,
	textblocksFeature,
	textfieldsFeature,
	videosFeature
} from '@yuno/admin/features/textfield-pages/data-access/+state';

import * as imagesEffects from './+state/components/media-image/image.effects';
import * as notificationsEffects from './+state/components/media-notification/notification.effects';
import * as videosEffects from './+state/components/media-video/video.effects';
import * as textButtonsEffects from './+state/components/text-button/text-button.effects';
import * as textCustomsEffects from './+state/components/text-custom/text-custom.effects';
import * as textLinksEffects from './+state/components/text-link/text-link.effects';
import * as textListsEffects from './+state/components/text-list/text-list.effects';
import * as textNewsItemsEffects from './+state/components/text-news-item/text-news-item.effects';
import * as textTogglesEffects from './+state/components/text-toggle/text-toggle.effects';
import * as textblocksEffects from './+state/components/textblock/textblock.effects';
import * as pagesEffects from './+state/pages/pages.effects';
import * as textfieldsEffects from './+state/textfield/textfield.effects';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(textfieldsFeature),
		provideState(pagesFeature),
		provideState(imagesFeature),
		provideState(videosFeature),
		provideState(notificationFeature),
		provideState(textblocksFeature),
		provideState(textButtonsFeature),
		provideState(textCustomsFeature),
		provideState(textLinksFeature),
		provideState(textListsFeature),
		provideState(textNewsItemsFeature),
		provideState(textTogglesFeature),
		provideEffects([
			pagesEffects,
			textfieldsEffects,
			imagesEffects,
			videosEffects,
			notificationsEffects,
			textblocksEffects,
			textButtonsEffects,
			textCustomsEffects,
			textLinksEffects,
			textListsEffects,
			textNewsItemsEffects,
			textTogglesEffects
		]),

		TextfieldFacade,
		PagesFacade,
		ImageFacade,
		VideoFacade,
		NotificationFacade,
		TextblockFacade,
		TextButtonFacade,
		TextCustomFacade,
		TextLinkFacade,
		TextListFacade,
		TextNewsItemFacade,
		TextToggleFacade
	]
})
export class TextfieldPagesDataAccessModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as sidemenuEffects from './+state/sidemenu.effects';
import { SidemenuFacade } from './+state/sidemenu.facade';
import { sidemenuFeature } from './+state/sidemenu.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(sidemenuFeature), provideEffects([sidemenuEffects]), SidemenuFacade]
})
export class SidemenuDataAccessModule {}

import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent, DragRowComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { YunoButtonComponent } from '@yuno/project-atlas/ui';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		DragRowComponent,
		AnnotationComponent,
		YunoButtonComponent,
		CdkDrag,
		CdkDropList
	],
	selector: 'yuno-admin-atlas-language-settings',
	templateUrl: './atlas-language-settings.component.html',

	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasLanguageSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);

	/* CDK DragList Drop Event */
	drop(event: CdkDragDrop<FormArray<FormControl<string>>>): void {
		moveItemInFormArray(this.service.languages, event.previousIndex, event.currentIndex);
	}
}

<ng-template #content>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="mr-2 inline-block h-6 w-6"
		viewBox="0 0 20 20"
		[ngStyle]="{ fill: fillColor }">
		<path
			fill-rule="evenodd"
			d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
			clip-rule="evenodd" />
	</svg>
	<ng-content></ng-content>
</ng-template>

@switch (!!url) {
	@case (true) {
		@switch (absolute) {
			@case (true) {
				<a [href]="url" rel="noopener" target="_blank">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</a>
			}
			@default {
				<a [routerLink]="link" [routerLinkActive]="'active'">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</a>
			}
		}
	}
	@default {
		<span class="cursor-pointer">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</span>
	}
}

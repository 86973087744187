import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

import { safeHtmlIframesOptions } from './helpers';

@Pipe({
	name: 'safeHtmlIframe',
	standalone: true
})
export class SafeHtmlIframePipe implements PipeTransform {
	private plaform = inject(PLATFORM_ID);
	private sanitizer = inject(DomSanitizer);

	public transform(value?: string): SafeHtml | string {
		if (!value) {
			return '';
		}

		if (isPlatformBrowser(this.plaform)) {
			/* sanitize the HTMLString */
			const sanitizedHtml = sanitizeHtml(value, safeHtmlIframesOptions);
			/* convert to HTMLElement */
			const html = this.htmlStringToElement(sanitizedHtml);

			/* Replace HTML elements with Yuno elements */
			const html_ahref = this.transformAnchors(html);
			const html_iframe = this.replaceIframes(html_ahref);
			const html_videos = this.replaceVideos(html_iframe);
			const html_images = this.replaceImages(html_videos);

			return this.sanitizer.bypassSecurityTrustHtml(html_images.outerHTML);
		}

		return value;
	}

	private htmlStringToElement(html: string): HTMLElement {
		/* Remove whitespaces */
		html = html.trim();

		/* Create HTMLElement */
		return new DOMParser().parseFromString(html, 'text/html').body;
	}

	// Replace all Iframes used in the Application
	private replaceIframes(html: HTMLElement): HTMLElement {
		/* Find all Iframes */
		html.querySelectorAll('iframe').forEach((iframe: HTMLIFrameElement) => {
			const url = iframe.src;

			const newDiv = document.createElement('yuno-text-iframe');
			newDiv.setAttribute('src', url);

			if (iframe.parentElement && iframe.parentNode) {
				/* Remove padding and position of Parent Style incase of Vimeo Embed Responsive is used */
				iframe.parentElement.style.padding = '';
				iframe.parentElement.style.position = '';
				iframe.replaceWith(newDiv);

				// solves the creation of a secondary div
				newDiv.textContent = '';
			}
		});

		return html;
	}

	// Replaces all Shadowbox
	private replaceImages(html: HTMLElement): HTMLElement {
		html.querySelectorAll<HTMLImageElement>('.shadowbox').forEach((image: HTMLImageElement) => {
			const url = image.src;
			const newDiv = document.createElement('yuno-text-image');

			newDiv.setAttribute('src', url);

			if (!image.classList.contains('nozoom')) {
				newDiv.setAttribute('zoomable', 'true');
			}

			if (image.classList.contains('h-full')) {
				newDiv.setAttribute('fullheight', 'true');
			}

			if (image.parentElement?.lastChild) {
				const descr = image.nextElementSibling as HTMLElement;
				if (descr instanceof HTMLSpanElement) {
					newDiv.setAttribute('description', descr.innerText);

					// remove the description
					// otherwise it will appear twice
					descr.remove();
				}
			}

			image.replaceWith(newDiv);

			// solves the creation of a secondary div
			newDiv.textContent = '';
		});

		return html;
	}

	private replaceVideos(html: HTMLElement): HTMLElement {
		const videos = html.querySelectorAll('.video-frame');
		if (videos.length < 1) {
			return html;
		}

		for (const video of Array.from(videos)) {
			const url = video.innerHTML.toString();

			const newDiv = document.createElement('yuno-text-video');
			newDiv.setAttribute('videourl', url);

			video.replaceWith(newDiv);

			// solves the creation of a secondary div
			newDiv.textContent = '';
		}

		return html;
	}

	// Add rel noopner and target=blank to a-hrefs
	// https://github.com/apostrophecms/sanitize-html/issues/584
	private transformAnchors(html: HTMLElement): HTMLElement {
		/* Find all AnchorElements */
		const anchors = html.querySelectorAll('a[href^="http"]');
		anchors.forEach(anchor => {
			anchor.setAttribute('rel', 'noopener noreferrer nofollow');
			anchor.setAttribute('target', '_blank');
		});

		return html;
	}
}

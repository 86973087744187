import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output
} from '@angular/core';

@Component({
	selector: 'yuno-admin-sidemenu',
	templateUrl: './sidemenu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
	private _opened = true;

	@Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	set opened(bool: boolean) {
		this._opened = bool;
	}

	get opened(): boolean {
		return this._opened;
	}

	public innerWidth: number;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.innerWidth = window.innerWidth;
		this.innerWidth <= 768 && (this._opened = false);
	}

	toggleOpened(): void {
		this.opened = !this.opened;
		this.openedChange.emit(this.opened);
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		this.innerWidth <= 768 && (this._opened = false);
	}
}

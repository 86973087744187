import {
	ConnectedPosition,
	Overlay,
	OverlayPositionBuilder,
	OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

import { waitFor } from '@yuno/shared/helpers';

import { TooltipComponent, TooltipDirectionClass } from './tooltip.component';

const positionLeft: ConnectedPosition = {
	originX: 'start',
	originY: 'center',
	overlayX: 'end',
	overlayY: 'center',
	offsetY: 0,
	offsetX: -20
};

const positionRight: ConnectedPosition = {
	originX: 'end',
	originY: 'center',
	overlayX: 'start',
	overlayY: 'center',
	offsetY: 0,
	offsetX: 20
};

const positionTop: ConnectedPosition = {
	originX: 'center',
	originY: 'top',
	overlayX: 'center',
	overlayY: 'bottom',
	offsetY: -20,
	offsetX: 0
};

const positionBottom: ConnectedPosition = {
	originX: 'center',
	originY: 'bottom',
	overlayX: 'center',
	overlayY: 'top',
	offsetY: 20,
	offsetX: 0
};

export const TooltipTimer = 333;
export type TooltipDirections = 'left' | 'right' | 'bottom' | 'top';

@Directive({
	selector: '[yunoTooltip],[yuno-tooltip]',
	exportAs: 'yunoTooltip',
	standalone: true
})
export class TooltipDirective implements OnDestroy {
	private timer: number;
	private overlayRef: OverlayRef;
	private tooltipRef: ComponentRef<TooltipComponent>;

	private mouseEnter = false;

	@Input() tooltipText: string;
	@Input() tooltipDelay = 0;
	@Input() tooltipOnHover = true;
	@Input() tooltipDirection: TooltipDirections = 'left';
	@Input() tooltipActiveDuration = 1500;

	@Input()
	set tooltipActive(active: boolean) {
		active ? this.show() : this.hide();
	}

	constructor(
		private overlay: Overlay,
		private overlayPositionBuilder: OverlayPositionBuilder,
		private elementRef: ElementRef
	) {}

	private returnOffset(offset: TooltipDirections): ConnectedPosition {
		switch (offset) {
			case 'left':
				return positionLeft;
			case 'right':
				return positionRight;
			case 'bottom':
				return positionBottom;
			case 'top':
			default:
				return positionTop;
		}
	}

	/**
	 * shows the Tooltip when clicked
	 * hides the Tooltip automatically after the tooltipActiveDuration
	 * Only does this when tooltipOnHover = false
	 */
	@HostListener('click')
	onClick(): void {
		if (this.tooltipOnHover || !this.tooltipText) {
			return;
		}

		this.show();
		this.timer = window.setTimeout(() => {
			this.tooltipRef.instance.hide = true;
			this.hide();
		}, this.tooltipActiveDuration);
	}

	/**
	 * shows the Tooltip when the mouse leaves the container
	 * Only does this when tooltipOnHover = true
	 */
	@HostListener('mouseenter')
	onMouseEnter(): void {
		this.mouseEnter = true;
		if (!this.tooltipOnHover || !this.tooltipText) {
			return;
		}

		this.show();
	}

	/**
	 * hides the Tooltip when the mouse leaves the container
	 * Only does this when tooltipOnHover = true
	 */
	@HostListener('mouseout')
	onMouseOut(): void {
		this.mouseEnter = false;
		if (!this.tooltipOnHover || !this.tooltipRef || !this.tooltipText) {
			return;
		}

		this.hide();
	}

	/**
	 * Spawns a TooltipComponent using the Angular Overlay CDK
	 */
	async show(): Promise<void> {
		await waitFor(this.tooltipDelay);
		if (!this.mouseEnter && this.tooltipOnHover) {
			return;
		}

		this.timer && clearTimeout(this.timer);

		const positionStrategy = this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions([this.returnOffset(this.tooltipDirection)]);

		// remove the previous overlayRef
		if (this.overlayRef) {
			this.remove();
		}

		this.overlayRef = this.overlay.create({ positionStrategy });
		this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
		this.tooltipRef.instance.text = this.tooltipText;
		this.tooltipRef.instance.hide = false;

		this.tooltipRef.instance.className = ('tooltip__' +
			this.tooltipDirection) as TooltipDirectionClass;
	}

	// start removing the Tooltip Component with a delay
	hide(): void {
		if (!this.tooltipRef) {
			return;
		}

		this.tooltipRef.instance.hide = true;
		this.timer = window.setTimeout(() => {
			this.remove();
		}, TooltipTimer);
	}

	// remove the Tooltip Component
	remove(): void {
		this.overlayRef.detach();
		this.overlayRef.dispose();
	}

	ngOnDestroy(): void {
		this.hide();
	}
}

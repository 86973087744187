import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	CircleLayerSpecification,
	FillLayerSpecification,
	LineLayerSpecification,
	Map
} from 'maplibre-gl';
import { Subject } from 'rxjs';

import { AngularPipesModule } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { Layer, Legend, LegendOptionsDefault } from '@yuno/api/interface';

const defaultGradiant: LegendOptionsDefault['gradiant'] = [
	{
		color: '#2121E5',
		offset: 0,
		title: ''
	},
	{
		color: '#206DFF',
		offset: 100,
		title: ''
	}
];

@Component({
	selector: 'yuno-legenda-item-content',
	standalone: true,
	imports: [CommonModule, AngularPipesModule, AngularSvgLoaderModule],
	templateUrl: './legenda-item-content.component.html',
	styleUrls: ['./legenda-item-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendaItemContentComponent implements OnInit, OnDestroy, AfterViewInit {
	private destroyed$ = new Subject<boolean>();
	@Input() map: Map;
	@Input() data: LegendOptionsDefault;
	@Input() parentClass?: string;
	@Input() layer?: Layer;
	@Input() language: string | undefined = 'nl';

	@ViewChild('svgGradiant', { static: false }) svgGradiant: ElementRef;

	@HostBinding('class') private sizingClass = '';
	@HostBinding('class.list') @Input() list = false;
	@HostBinding('class.hidden') private hidden = false;

	private _sizing: Legend['size'] = 24;
	@Input() set sizing(size: Legend['size']) {
		switch (size) {
			case 24:
				this.sizingClass = '';
				break;
			case 40:
				this.sizingClass = 'legend-width-2';
				break;
			case 64:
				this.sizingClass = 'legend-width-3';
				break;
			case 80:
				this.sizingClass = 'legend-width-4';
				break;
			case 104:
				this.sizingClass = 'legend-width-5';
				break;
		}

		this._sizing = size;
	}

	get sizing(): Legend['size'] {
		return this._sizing;
	}

	type?: string;
	color: string;
	border: string;
	borderColor: string;

	svg: string;
	htmlTypes = ['circle', 'fill', 'line'];

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.getLayerData();

		// if (this.data && this.data?.activeLayers && this.data?.activeLayers?.length >= 1) {
		// 		if (!this.map) {
		// return;
		// }

		// this.map.on('moveend', () => this.checkActive());
		// }
	}

	ngAfterViewInit(): void {
		this.data?.gradiant &&
			this.data.gradiant.length > 0 &&
			setTimeout(() => {
				this.createSVGGradiant();
			});
	}

	getImageSize(): string {
		return `width: ${(this.sizing || 24) / 16}rem; height: ${(this.data.iconHeight || 16) / 16}rem;`;
	}

	// TODO: FIX PERFORMANCE ISSUES WITH LEGENDA AND AUTO HIDING LAYERS
	// checkActive(): void {
	// 	if (!this.data || !this.map) {
	// 		return;
	// 	}
	//
	//  // UNUSED AND CAUSING PERFORMANCE ISSUES
	// 	// const map = this.map;
	// 	// const visibleLayers: { [key: string]: Layer } = {};
	// 	//
	// 	// const features = map.queryRenderedFeatures();
	// 	// for (const feature of features) {
	// 	// 	visibleLayers[feature.layer.id] = feature.layer;
	// 	// }
	//
	// 	const layers = this.data?.activeLayers?.map(layer => layer.id);
	// 	const visibility = layers ? this.getLayerVisibilityState(layers) : true;
	// 	this.hidden = !visibility;
	//
	// 	this.cdr.markForCheck();
	// }

	spanBoth(): boolean {
		return this.list || this.data.bold || (!this.data?.svg && !this.data?.image && !this.type);
	}

	// getLayerVisibilityState(layers: string[]): boolean {
	// 	let visibility = false;
	//
	// 	for (const layer of layers) {
	// 		if (!visibility && this.map.getLayer(layer)) {
	// 			visibility = this.map.getLayoutProperty(layer, 'visibility') === 'visible';
	// 		}
	// 	}
	//
	// 	return visibility;
	// }

	/* Generate Legenda Icons */
	getLayerData(): void {
		if (!this.data?.class && !this.parentClass && this.layer?.type) {
			this.type = this.layer?.type;
			this.color = this.data?.color || this.getColor();
			this.border = this.data?.border || '2px solid transparent';
			this.borderColor = this.data?.borderColor || this.getBorderColor();

			if (!this.htmlTypes.includes(this.type)) {
				this.svg = this.data?.svg || this.getSVG(this.type);
			}
			return;
		}

		if (this.data?.class || this.parentClass) {
			this.type = (this.data?.class || this.parentClass) as string;
			if (!this.htmlTypes.includes(this.type)) {
				this.svg = this.data?.svg || this.getSVG(this.type);
			}

			this.color = this.data?.color || 'transparent';
			this.border = this.data?.border || '2px solid transparent';
			this.borderColor = this.data?.borderColor || 'none';
		}
	}

	getColor(): string {
		if (!this.layer || !this.layer.paint) {
			return 'transparent';
		}

		const paint = this.layer.paint;
		let color = 'transparent';

		switch (this.type) {
			case 'fill':
				color = (paint as FillLayerSpecification['paint'])?.['fill-color'] as string;
				break;
			case 'circle':
				color = (paint as CircleLayerSpecification['paint'])?.['circle-color'] as string;
				break;
			case 'line':
				color = (paint as LineLayerSpecification['paint'])?.['line-color'] as string;
				break;
		}

		return color;
	}

	getBorderColor(): string {
		if (!this.layer || !this.layer.paint) {
			return 'none';
		}

		const paint = this.layer.paint;

		let fillColor = 'none';

		switch (this.type) {
			case 'fill':
				fillColor = (paint as FillLayerSpecification['paint'])?.[
					'fill-outline-color'
				] as string;
				break;
			case 'circle':
				fillColor = (paint as CircleLayerSpecification['paint'])?.[
					'circle-stroke-color'
				] as string;
				break;
		}

		if (fillColor !== 'none') {
			return '1px solid ' + fillColor;
		}

		return 'none';
	}

	getSVG(str: string): string {
		const map: { [key: string]: string } = {
			vp: `https://cdn.projectatlas.app/content/static/icons/legenda/vp.svg`,
			mp: `https://cdn.projectatlas.app/content/static/icons/legenda/mp.svg`,
			trace: `https://cdn.projectatlas.app/content/static/icons/legenda/dotted_line.svg`,
			dotted: `https://cdn.projectatlas.app/content/static/icons/legenda/projectgrens.svg`,
			dashed: `https://cdn.projectatlas.app/content/static/icons/legenda/dashed_line.svg`,
			fill: `https://cdn.projectatlas.app/content/static/icons/legenda/fill.svg`,
			area: `https://cdn.projectatlas.app/content/static/icons/legenda/area.svg`,
			area_outlined: `https://cdn.projectatlas.app/content/static/icons/legenda/area_outline.svg`,
			'area-outlined': `https://cdn.projectatlas.app/content/static/icons/legenda/area_outline.svg`,
			'area-outlined-white': `https://cdn.projectatlas.app/content/static/icons/legenda/area_outline_white.svg`,
			area_outlined_white: `https://cdn.projectatlas.app/content/static/icons/legenda/area_outline_white.svg`,
			marked: `https://cdn.projectatlas.app/content/static/icons/legenda/marked.svg`,
			raster: `https://cdn.projectatlas.app/content/static/icons/legenda/raster.svg`,
			temp: `https://cdn.projectatlas.app/content/static/icons/legenda/temp.svg`,
			water: `https://cdn.projectatlas.app/content/static/icons/legenda/water.svg`,
			'lightning-white': `https://cdn.projectatlas.app/content/static/icons/legenda/lightning_white.svg`,
			lightning_white: `https://cdn.projectatlas.app/content/static/icons/legenda/lightning_white.svg`,
			'lightning-black': `https://cdn.projectatlas.app/content/static/icons/legenda/lightning_black.svg`,
			lightning_black: `https://cdn.projectatlas.app/content/static/icons/legenda/lightning_black.svg`,
			amoveren: `https://cdn.projectatlas.app/content/static/icons/legenda/amover_line.svg`,
			duoline: `https://cdn.projectatlas.app/content/static/icons/legenda/duo_line.svg`,
			'duo-line': `https://cdn.projectatlas.app/content/static/icons/legenda/duo_line.svg`,
			'line-value': `https://cdn.projectatlas.app/content/static/icons/legenda/line-value.svg`,
			line: `https://cdn.projectatlas.app/content/static/icons/legenda/line.svg`,
			circle: `https://cdn.projectatlas.app/content/static/icons/legenda/circle.svg`,
			'circle-outline': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-outline.svg`,
			'circle-outline-white': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-outline-white.svg`,
			'circle-outline-black': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-outline-black.svg`,
			'circle-white-outline-color': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-white-outline-color.svg`,
			'circle-black-outline-color': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-black-outline-color.svg`,
			'circle-number': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-number.svg`,
			'circle-outline-white-number': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-outline-white-number.svg`,
			'circle-outline-black-number': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-outline-black-number.svg`,
			'circle-white-outline-color-number': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-white-outline-color-number.svg`,
			'circle-black-outline-color-number': `https://cdn.projectatlas.app/content/static/icons/legenda/circle-black-outline-color-number.svg`,
			home: `https://cdn.projectatlas.app/content/static/icons/legenda/home.svg`,
			nature: `https://cdn.projectatlas.app/content/static/icons/legenda/nature.svg`,
			transparent: `transparent`
		};

		return map[str] || map['circle'];
	}

	createSVGGradiant(): void {
		const element = this.svgGradiant;
		const options = this.data?.gradiant;

		/* Store the SVG namespace for easy reuse. */
		const svgns = 'http://www.w3.org/2000/svg';

		/* Height in Pixels */
		const height = this.data?.gradiantHeight || 80;
		const width = 12;

		/* Create <defs>, <linearGradient> and <rect> elements using createElementNS to apply the SVG namespace.
		https://developer.mozilla.org/en-US/docs/Web/API/Document/createElementNS) */
		const defs = document.createElementNS(svgns, 'defs');
		const gradient = document.createElementNS(svgns, 'linearGradient');
		const rect = document.createElementNS(svgns, 'rect');

		/*
			Store an array of stop information for the <linearGradient>
			Sort the Array by offset or Use a default array
		*/
		const stops =
			options && options.length > 1
				? options.sort((a, b) => (a.offset || 0) - (b.offset || 0))
				: defaultGradiant;

		if (!stops) {
			return;
		}

		/* Parses an array of stop information and appends <stop> elements to the <linearGradient> */
		for (const [index, value] of stops.entries()) {
			let setOffset = value?.offset;
			if (!setOffset) {
				const length = stops.length;
				setOffset = (100 / (length - 1)) * index;
			}

			/* Create a <stop> element and set its offset based on the position of the for loop. */
			if (value?.color) {
				const stop = document.createElementNS(svgns, 'stop');
				stop.setAttribute('offset', setOffset + '%');
				stop.setAttribute('stop-color', value?.color);

				/* Add the stop to the <lineargradient> element. */
				gradient.appendChild(stop);
			}

			if (value?.title) {
				const text = document.createElementNS(svgns, 'text');

				const topOffset = (height / 100) * setOffset;
				/* Compensate text Height */
				const offset = 10 - (10 / 100) * setOffset;

				text.setAttribute('y', (topOffset + offset).toString());
				text.setAttribute('x', '28');

				text.textContent = value?.title;

				element.nativeElement.appendChild(text);
			}
		}

		/* Apply the <lineargradient> to <defs> */
		const id = 'svg' + this.makeid(10);
		gradient.id = id;
		defs.appendChild(gradient);
		gradient.setAttribute('gradientTransform', 'rotate(90)');

		/* Setup the <rect> element. */
		rect.setAttribute('fill', "url('#" + id + "')");
		rect.setAttribute('width', width + 'px');
		rect.setAttribute('height', height + 'px');

		/* Assign an id, classname, width and height */
		element.nativeElement.setAttribute('width', '100%');
		element.nativeElement.setAttribute('height', height + 'px');
		element.nativeElement.setAttribute('version', '1.1');
		element.nativeElement.setAttribute('xmlns', svgns);

		/* Add the <defs> and <rect> elements to <svg> */
		element.nativeElement.appendChild(defs);
		element.nativeElement.appendChild(rect);

		this.cdr.markForCheck();
	}

	/* Create a random ID for the GradiantSVG */
	makeid(length: number): string {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CanvasImageComponent } from './canvas-image.component';

export class OverlayInjector {
	data: string;
}
export const OVERLAY_INJECTOR = new InjectionToken<OverlayInjector>('OverlayInjector');

@Injectable({
	providedIn: 'root'
})
export class CanvasImageService {
	private _overlayRef: OverlayRef;
	active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private injector: Injector,
		private overlay: Overlay
	) {}

	setOverlay(data?: any): void {
		this.active$.next(true);

		if (!this.injector) {
			console.warn('NO INJECTOR INSIDE OVERLAY SERVICE');
			return;
		}

		if (this._overlayRef) {
			this._overlayRef.dispose();
		}

		this._overlayRef = this.overlay.create({
			panelClass: 'yuno-overlay'
		});

		const inject = Injector.create({
			parent: this.injector,
			providers: [
				{
					provide: OverlayInjector,
					useValue: {
						data: data
					}
				}
			]
		});

		const comp = new ComponentPortal(CanvasImageComponent, null, inject);
		this._overlayRef.attach(comp);
	}

	closeOverlay(): void {
		if (!this._overlayRef) {
			return;
		}

		this.active$.next(false);
		this._overlayRef.dispose();
	}
}

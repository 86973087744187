import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as cookieEffects from './+state/cookies/cookies.effects';
import * as privacyStatementCustomEffects from './+state/privacy-statement-projects/privacy-statement.effects';
import * as privacyStatementEffects from './+state/privacy-statement-xkp/privacy-statement-xkp.effects';
import { cookieFeature } from './+state/cookies';
import { CookiesFacade } from './+state/cookies/cookies.facade';
import { PrivacyProjectFacade, privacyCustomFeature } from './+state/privacy-statement-projects';
import { PrivacyFacade, privacyFeature } from './+state/privacy-statement-xkp';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(cookieFeature),
		provideState(privacyFeature),
		provideState(privacyCustomFeature),
		provideState(cookieFeature),
		provideEffects([cookieEffects, privacyStatementEffects, privacyStatementCustomEffects]),
		CookiesFacade,
		PrivacyFacade,
		PrivacyProjectFacade
	]
})
export class PrivacyCookieDataAccessModule {}

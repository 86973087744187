import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
	MediaImageButton,
	MediaImageButtonSize,
	MediaImageButtonSizeEnum
} from '@yuno/api/interface';

@Component({
	selector: 'yuno-button-card',
	templateUrl: './button-card.component.html',
	styleUrls: ['./button-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCardComponent {
	button: MediaImageButton | undefined;

	buttonSize: MediaImageButtonSize = MediaImageButtonSizeEnum.large;
	buttonWide = true;
	buttonImage: string | undefined;

	@Input() language: string | undefined = 'nl';
	@Input()
	set data(val: MediaImageButton | undefined) {
		if (!val) {
			return;
		}

		this.button = val;
		this.size = val.size || MediaImageButtonSizeEnum.large;
		this.wide = !!val.wide;

		this.buttonImage = val.src;
	}

	@Input()
	set size(val: MediaImageButtonSizeEnum | undefined) {
		this.buttonSize = val || MediaImageButtonSizeEnum.large;
	}
	get size(): MediaImageButtonSizeEnum {
		return this.buttonSize;
	}

	@Input()
	set wide(val: boolean | undefined) {
		this.buttonWide = val ?? true;
	}
	get wide(): boolean {
		return this.buttonWide;
	}

	@Input()
	set imageSrc(val: string | undefined) {
		this.buttonImage = val;
	}
	get imageSrc(): string | undefined {
		return this.buttonImage;
	}

	generateUrl(): string | null {
		if (this.button?.file) {
			return this.getFileUrl();
		}

		return this.getImageUrl();
	}

	getImageUrl(): string | null {
		if (!this.button?.image) {
			return null;
		}

		return `https://projectatlas.app/api/media/app/${this.button?.image?.metadata?.appId}/${this.button?.image?.filename}`;
	}

	getFileUrl(): string | null {
		return this.button?.file?.data?.url || null;
	}
}

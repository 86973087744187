import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SidebarGroupComponent } from './sidebar-group/sidebar-group.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { SidebarUserComponent } from './sidebar-user/sidebar-user.component';
import { SidemenuComponent } from './sidemenu.component';

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: [
		SidemenuComponent,
		SidebarGroupComponent,
		SidebarItemComponent,
		SidebarUserComponent
	],
	exports: [SidemenuComponent, SidebarGroupComponent, SidebarItemComponent, SidebarUserComponent]
})
export class YunoSidemenuModule {}

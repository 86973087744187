import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type TooltipDirectionClass =
	| 'tooltip__left'
	| 'tooltip__right'
	| 'tooltip__bottom'
	| 'tooltip__top';

@Component({
	selector: 'yuno-tooltip',
	standalone: true,
	template: `<div class="tooltip-container">{{ text }}</div>`,
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('tooltip', [
			transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate(300, style({ opacity: 0 }))])
		])
	]
})
export class TooltipComponent {
	@Input() text: string;
	@HostBinding('class') @Input() className: TooltipDirectionClass;
	@HostBinding('class.tooltip__hide') @Input() hide = false;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as mapEffects from './+state/map.effects';
import { MapFacade } from './+state/map.facade';
import { mapFeature } from './+state/map.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(mapFeature), provideEffects([mapEffects]), MapFacade]
})
export class MapDataAccessModule {}

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	Input,
	OnInit,
	ViewChild,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationEnd, Router } from '@angular/router';
import { delay } from 'rxjs';

export const timeDelays = {
	delay: 250
};

@Component({
	selector: 'yuno-sidebar-group',
	templateUrl: './sidebar-group.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarGroupComponent implements OnInit, AfterViewInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);
	private readonly cdr = inject(ChangeDetectorRef);

	@Input({ required: true }) display: string;
	@ViewChild('listContainer', { static: false }) private listContainer!: ElementRef;
	isActive = false;

	ngOnInit(): void {
		this.subscribeToRouterEvents();
	}

	setFocus(): void {
		this.isActive = true;
	}
	removeFocus(): void {
		this.isActive ? this.checkActiveElements() : (this.isActive = true);
	}

	// Subscribes to Router Events
	// when NavigationEnd is fired, we check the
	// current active children
	//
	// add a small delay, to let the router update fully
	// before checking the Current Activated Route
	// else there is a possibility of collapsing while
	// in the current Group
	subscribeToRouterEvents(): void {
		this.router.events
			.pipe(takeUntilDestroyed(this.destroyRef), delay(100))
			.subscribe((event: Event) => {
				if (event instanceof NavigationEnd) {
					this.checkActiveElements();
				}
			});
	}

	// Check elements once on load
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.checkActiveElements();
		}, 100);
	}

	// Checks al children to see if
	// one of the elements has the class 'active'
	// when it does, that should mean the router link is active
	// on that element en this group should also be activated
	checkActiveElements(): void {
		if (!this.listContainer) {
			this.isActive = false;
			return;
		}
		const elements: Element[] = Array.from(this.listContainer?.nativeElement.children);

		this.isActive = elements.some(el => el.children[0].className.includes('active'));
		this.cdr.detectChanges();
	}
}

<router-outlet></router-outlet>

@if (data$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="false"
		[pageOptions]="{
			pageSize: 25,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="data.datasets"
		[buttons]="tableButtons"
		[columns]="[
			{ key: 'id', label: 'Dataset' },
			{ key: 'fences', label: 'Fences' },
			{ key: 'layers', label: 'Layers' },
			{ key: 'markers', label: 'Markers' },
			{ key: 'panoramas', label: 'Panoramas' },
			{ key: 'objects', label: 'Objects' },
			{ key: 'categories', label: 'Categories' },
			{ key: 'states', label: 'States' }
		]"
		(clicked)="onSelect($event)">
		<button yuno-admin-add-button (click)="onCreate()">New Dataset</button>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>

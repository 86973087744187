import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EditorGuard } from '@yuno/admin/core/guards';

import { MarkersViewerRoutingComponent } from './components/routing/routing.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./view.component').then(c => c.MarkerViewerComponent),
		children: [
			{
				path: 'map',
				component: MarkersViewerRoutingComponent,
				children: [
					{
						path: 'edit/:id',
						canActivate: [EditorGuard],
						loadComponent: () =>
							import('../editor/marker-editor.component').then(
								c => c.MarkerEditorComponent
							)
					},
					{
						path: 'create',
						canActivate: [EditorGuard],
						loadComponent: () =>
							import('../editor/marker-editor.component').then(
								c => c.MarkerEditorComponent
							)
					}
				]
			},
			{
				path: 'list',
				loadComponent: () =>
					import('./components/list-viewer/list-viewer.component').then(
						c => c.MarkersListViewerComponent
					),
				children: [
					{
						path: 'edit/:id',
						canActivate: [EditorGuard],
						loadComponent: () =>
							import('../editor/marker-editor.component').then(
								c => c.MarkerEditorComponent
							)
					}
				]
			},
			{ path: '**', redirectTo: 'map' }
		]
	},
	{
		path: 'create',
		canActivate: [EditorGuard],
		loadComponent: () =>
			import('../editor/marker-editor.component').then(c => c.MarkerEditorComponent)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class MarkerViewerRoutingModule {}

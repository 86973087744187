import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup } from '@angular/forms';
import { combineLatest, map, startWith } from 'rxjs';

import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import { PanoramasFacade } from '@yuno/admin/features/panoramas';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminButtonComponent,
	YunoAdminButtonTableComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { Panorama } from '@yuno/api/interface';

import { CustomStatesEditorComponent } from '../../custom-states-editor/custom-states-editor.component';
import { DatasetEditorService, PanoramaDataStatesForm } from '../../dataset-editor.service';

@Component({
	selector: 'yuno-admin-dataset-listview-panoramas',
	standalone: true,
	imports: [
		YunoAdminTableComponent,
		YunoAdminButtonComponent,
		YunoAdminButtonTableComponent,
		EventsFormsComponent,
		CustomStatesEditorComponent,
		AsyncPipe
	],
	templateUrl: './panoramas.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewPanoramasComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly facade = inject(PanoramasFacade);
	readonly service = inject(DatasetEditorService);

	// Initialize event index and a variable for copying event data.
	copyEventData?: FormArray<FormGroup<PanoramaDataStatesForm>>;

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		customStates: this.service.customStates$.pipe(startWith(false)),
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => data?.data?.panoramas.map(data => data.panorama as TableRow) || [])
		),
		panoramas: this.facade.panoramas$
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Panoramas' }];

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'Panoramas',
			e.selection.map(data => ({ panorama: data }))
		);
	}

	// Triggers when selecting or deselecting a row in the table
	// when adding an item, also adds an event key to the object
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove('Panoramas', 'panorama', e.row);
			return;
		}

		this.service.add('Panoramas', { panorama: e.row });
	}

	// Function to determine the enabled state and event count for a hovered item.
	eventButtonsData(row: Partial<Panorama>): {
		enabled: boolean;
		eventCount: number;
	} {
		if (!row) {
			return { enabled: false, eventCount: 0 };
		}

		const formGroups = this.service.dataPanoramas.controls as FormGroup[];
		const data = formGroups.find(
			group => group.controls?.['panorama'].get('_id')?.value === row._id
		);

		if (!data) {
			return {
				enabled: false,
				eventCount: 0
			};
		}

		return {
			enabled: true,
			eventCount: data.value.states.length
		};
	}

	// Event handler for clicking on an event button.
	onClickEventButton(row: Partial<Panorama>): void {
		const formGroups = this.service.dataPanoramas.controls as FormGroup[];
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['panorama'].get('_id')?.value === row._id
		);

		if (foundIndex < 0) {
			return;
		}

		this.service.eventIndex = foundIndex;
		this.service.customStates$.next(true);
	}

	// Function to copy events data for a specific item.
	copyEvents(row: Partial<Panorama>): void {
		const formGroups = this.service.dataPanoramas.controls as FormGroup[];
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['panorama'].get('_id')?.value === row._id
		);

		if (foundIndex < 0) {
			return;
		}

		const control = this.service.dataPanoramas.controls[foundIndex];
		this.copyEventData = control.controls.states;
	}

	// Function to paste copied events data to a specific item.
	pasteEvents(row: Partial<Panorama>): void {
		if (!this.copyEventData) {
			return;
		}

		// All formgroups in the data panoramas form array.
		const formGroups = this.service.dataPanoramas.controls as FormGroup[];
		// Get the index of the formgroup that matches the event id.
		const foundIndex = formGroups.findIndex(
			group => group.controls?.['panorama'].get('_id')?.value === row._id
		);

		if (foundIndex < 0) {
			return;
		}

		// if the index if found, get the formgroup and the events form array.
		const control = this.service.dataPanoramas.controls[foundIndex];

		// First we clear all current events
		// from the events form array
		control.controls.states?.clear();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const eventData of this.copyEventData.value) {
			control.controls.states.push(this.service.createCustomState());
		}
		control.controls.states.patchValue(this.copyEventData.value);
	}

	// Function to clear the clipboard (reset copied event data).
	clearClipboard(): void {
		this.copyEventData = undefined;
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as themeEffects from './+state/theme.effects';
import { ThemeFacade, themesFeature } from './+state';

// import { themeFeature } from './+state/theme.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(themesFeature), provideEffects([themeEffects]), ThemeFacade]
})
export class ThemesDataAccessModule {}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { COOKIE_DOMAIN } from '@yuno/admin/core/services';
import { AppConfigurationDataAccessModule } from '@yuno/admin/features/app-configuration';
import { AppCreateDataAccessModule } from '@yuno/admin/features/app-create';
import { AppDataDataAccessModule } from '@yuno/admin/features/app-data';
import { AppsDataAccessModule } from '@yuno/admin/features/apps';
import { ClientsDataAccessModule } from '@yuno/admin/features/clients';
import { DashboardDataAccessModule } from '@yuno/admin/features/dashboard';
import { DatasetsDataAccessModule } from '@yuno/admin/features/datasets/data-access';
import { EventsDataAccessModule } from '@yuno/admin/features/events';
import { FencesDataAccessModule } from '@yuno/admin/features/fences';
import { FileLibraryDataAccessModule } from '@yuno/admin/features/file-library';
import { GeoPhotosDataAccessModule } from '@yuno/admin/features/geophotos/data-access';
import { LayersDataAccessModule } from '@yuno/admin/features/layers/data-access';
import { LayoutDataAccessModule } from '@yuno/admin/features/layout';
import { LegendDataAccessModule } from '@yuno/admin/features/legends';
import { MapDataAccessModule } from '@yuno/admin/features/map';
import { MapUiDataAccessModule } from '@yuno/admin/features/map-ui';
import { MarkersDataAccessModule } from '@yuno/admin/features/markers/data-access';
import { MediaDataAccessModule } from '@yuno/admin/features/media/data-access';
import { NavbarDataAccessModule } from '@yuno/admin/features/navbar';
import { ObjectsDataAccessModule } from '@yuno/admin/features/objects';
import { PanoramasDataAccessModule } from '@yuno/admin/features/panoramas';
import { ParticipationDataAccessModule } from '@yuno/admin/features/participation';
import { MarkerCategoriesDataAccessModule } from '@yuno/admin/features/place-markers/data-access';
import { PrivacyCookieDataAccessModule } from '@yuno/admin/features/privacy-and-cookies-editor/data-access';
import { SidemenuDataAccessModule } from '@yuno/admin/features/sidemenus/data-access';
import { SourcesDataAccessModule } from '@yuno/admin/features/sources/data-access';
import { StatesDataAccessModule } from '@yuno/admin/features/states';
import { TemplatesDataAccessModule } from '@yuno/admin/features/templates';
import { TextfieldPagesDataAccessModule } from '@yuno/admin/features/textfield-pages';
import { ThemesDataAccessModule } from '@yuno/admin/features/themes';
import { TilesetsDataAccessModule } from '@yuno/admin/features/tilesets';
import { UsersDataAccessModule } from '@yuno/admin/features/users';
import { VariantsDataAccessModule } from '@yuno/admin/features/variants/data-access';

@NgModule({
	declarations: [],
	imports: [
		MatDialogModule,
		MatSnackBarModule,
		AppsDataAccessModule,
		AppCreateDataAccessModule,
		AppConfigurationDataAccessModule,
		AppDataDataAccessModule,
		ClientsDataAccessModule,
		DashboardDataAccessModule,
		DatasetsDataAccessModule,
		EventsDataAccessModule,
		FencesDataAccessModule,
		FileLibraryDataAccessModule,
		GeoPhotosDataAccessModule,
		LayersDataAccessModule,
		LayoutDataAccessModule,
		LegendDataAccessModule,
		MapDataAccessModule,
		MapUiDataAccessModule,
		MarkersDataAccessModule,
		MarkerCategoriesDataAccessModule,
		MediaDataAccessModule,
		NavbarDataAccessModule,
		ObjectsDataAccessModule,
		PanoramasDataAccessModule,
		ParticipationDataAccessModule,
		PrivacyCookieDataAccessModule,
		SidemenuDataAccessModule,
		SourcesDataAccessModule,
		StatesDataAccessModule,
		TemplatesDataAccessModule,
		TextfieldPagesDataAccessModule,
		ThemesDataAccessModule,
		TilesetsDataAccessModule,
		UsersDataAccessModule,
		VariantsDataAccessModule
	]
})
export class AdminCoreModule {
	static withConfig(config: {
		consentKey?: string;
		cookieDomain?: string;
	}): ModuleWithProviders<AdminCoreModule> {
		return {
			ngModule: AdminCoreModule,
			providers: [
				{
					provide: COOKIE_DOMAIN,
					useValue: config.cookieDomain || COOKIE_DOMAIN
				}
			]
		};
	}
}

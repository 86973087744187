import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as datasetsEffects from './+state/datasets.effects';
import { DatasetsFacade } from './+state/datasets.facade';
import { datasetsFeature } from './+state/datasets.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(datasetsFeature), provideEffects([datasetsEffects]), DatasetsFacade]
})
export class DatasetsDataAccessModule {}

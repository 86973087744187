import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgComponent } from './svg/svg.component';

@NgModule({
	imports: [CommonModule, SvgComponent],
	exports: [SvgComponent]
})
export class AngularSvgLoaderModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as layersEffects from './+state/layers.effects';
import { LayersFacade } from './+state/layers.facade';
import { layersFeature } from './+state/layers.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(layersFeature), provideEffects([layersEffects]), LayersFacade]
})
export class LayersDataAccessModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { YunoAdminMarkersDownloadComponent } from '../geojson-downloader/download.component';
import { MarkerViewerUiSelectorsComponent } from '../ui-selectors/ui-selectors.component';

@Component({
	selector: 'yuno-admin-markers-routing',
	standalone: true,
	imports: [RouterModule, MarkerViewerUiSelectorsComponent, YunoAdminMarkersDownloadComponent],
	template: `
		<yuno-admin-marker-viewer-ui-selectors />
		<router-outlet></router-outlet>
		<yuno-admin-marker-viewer-download />
	`,
	styleUrls: ['routing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkersViewerRoutingComponent {}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { TextfieldDataService } from '@yuno/angular/textfield';
import { LanguagesArray } from '@yuno/api/interface';

export const languageResolver: ResolveFn<string> = (): Observable<string> => {
	const store = inject(Store);
	const transloco = inject(TranslocoService);
	const textfieldDataService = inject(TextfieldDataService);

	return store.select(appFeature.selectLanguage).pipe(
		map(lang => {
			let language = lang ? lang : 'nl';

			// Fallback language
			if (!LanguagesArray.includes(language)) {
				language = 'nl';
			}

			textfieldDataService.setLanguage(language);

			// Sets the Active Language when using Transloco files
			transloco.setActiveLang(language);
			return language;
		})
	);
};

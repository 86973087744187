import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { Map } from 'maplibre-gl';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageTypeAll, Layer, SidemenuLayers } from '@yuno/api/interface';

import { YunoButtonModule } from '../../../button';
import { AtlasSidemenuModule } from '../../sidemenu.module';

@Component({
	standalone: true,
	imports: [
		YunoButtonModule,
		TranslocoModule,
		FormsModule,
		NgStyle,
		NgClass,
		LanguagePipe,
		AtlasSidemenuModule
	],
	// providers: [provideTranslocoScope('toolbar')],
	selector: 'yuno-settings-layer-selector',
	template: `
		<ng-container *transloco="let t">
			<div
				class="flex h-auto w-full max-w-96 flex-col gap-4 overflow-auto rounded-md bg-white p-4 shadow-md">
				<!--	bg-yuno-gray-500 text-yuno-gray-100 -->
				<button
					(click)="closing.emit()"
					class="absolute right-0 top-0 m-3 cursor-pointer transition-colors duration-300 ease-in-out hover:text-yuno-gray-200">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="h-5 w-5">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M6 18 18 6M6 6l12 12" />
					</svg>
				</button>

				<span class="mr-12 text-lg font-semibold">{{
					t('sidemenu.settingsLayerSelectorTitle')
				}}</span>
				<div class="flex flex-col gap-1">
					@for (layer of items; track layer; let i = $index) {
						@switch (layer.group) {
							<!-- LAYER GROUP -->
							@case (true) {
								@if (layer.title) {
									<span
										class="text-sm font-semibold"
										[ngClass]="{ 'mt-2': i >= 1 }">
										{{ layer.title | languageSelect: language }}
									</span>
								}
								@for (item of layer.items; track item) {
									<yuno-atlas-sidemenu-settings-item
										[checked]="checkLayerVisibility(item.layer)"
										(clicked)="
											toggleLayerChange.emit({
												visible: $event,
												layers: item.layer
											})
										">
										{{ item.title | languageSelect: language }}
									</yuno-atlas-sidemenu-settings-item>
								}
							}
							<!-- LAYER -->
							@case (false) {
								<yuno-atlas-sidemenu-settings-item
									[checked]="checkLayerVisibility(layer.layer)"
									(clicked)="
										toggleLayerChange.emit({
											visible: $event,
											layers: layer.layer
										})
									">
									{{ layer.title | languageSelect: language }}
								</yuno-atlas-sidemenu-settings-item>
							}
						}
					}
				</div>
			</div>
		</ng-container>
	`,
	styleUrl: 'settings-layer-selector.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasSidemenuSettingsLayerSelectorComponent {
	@Input() map: Map;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Output() toggleLayerChange = new EventEmitter<{
		visible: boolean;
		layers?: string[] | Layer[] | undefined;
	}>();

	@Input() color?: string;
	@Input() backgroundColor?: string;

	@Input() language: LanguageTypeAll = 'nl';
	@Input() items: SidemenuLayers[] = [];

	@Output() closing: EventEmitter<void> = new EventEmitter<void>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	checkLayerVisibility(layers?: string[] | Layer[] | undefined): boolean {
		if (!layers || !this.map) {
			return false;
		}

		let visibility = false;
		for (const layerData of layers) {
			let layer = '';
			if (typeof layerData === 'string') {
				layer = layerData;
			} else {
				layer = layerData.id;
			}

			if (!visibility && this.map.getLayer(layer)) {
				visibility = this.map.getLayoutProperty(layer, 'visibility') === 'visible';
			}
		}

		return visibility;
	}
}

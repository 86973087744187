<div
	class="md:toggled absolute z-[120] mr-0 flex h-full w-96 flex-col pr-0 shadow-xl transition-all duration-500 ease-in-out md:relative md:w-64 md:shadow-none"
	[ngClass]="{ '-ml-96 md:-ml-64 md:mr-12': !opened }">
	<button
		class="absolute right-0 top-2 flex h-12 w-14 transform items-center justify-end rounded-lg bg-gray-800 text-gray-400 transition-transform duration-500 ease-in-out"
		[ngClass]="{ 'translate-x-12': !opened }"
		(click)="toggleOpened()">
		@if (opened) {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="mr-4 h-4 w-4"
				viewBox="0 0 20 20"
				fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
					clip-rule="evenodd" />
			</svg>
		}

		@if (!opened) {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="mr-3 h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M4 6h16M4 12h16M4 18h7" />
			</svg>
		}
	</button>

	<div class="flex flex-grow flex-col overflow-y-auto bg-gray-800">
		<div class="px-4">
			<ng-content select="[user]"></ng-content>
		</div>
		<!-- Items -->
		<div class="flex flex-1 flex-col overflow-y-auto px-4">
			<nav class="relative flex-1 space-y-1 bg-gray-800">
				<ng-content></ng-content>
				<div
					class="pointer-events-none sticky bottom-0 left-0 h-10 w-full bg-gradient-to-t from-gray-800 to-transparent"></div>
			</nav>
		</div>

		<div class="mb-4 flex flex-col items-center px-4">
			<ng-content select="[help]"></ng-content>
			<ng-content select="[switch]"></ng-content>

			<!-- LOGO -->
			<svg xmlns="http://www.w3.org/2000/svg" width="138.546" height="28.899" class="mb-4">
				<defs>
					<linearGradient id="a" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#13a1b1" />
						<stop offset="1" stop-color="#0e879b" />
					</linearGradient>
					<linearGradient id="b" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#45be53" />
						<stop offset="1" stop-color="#31ad39" />
					</linearGradient>
					<linearGradient id="c" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#1dbdc7" />
						<stop offset="1" stop-color="#12a4b2" />
					</linearGradient>
					<linearGradient id="d" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#2ca440" />
						<stop offset="1" stop-color="#218d30" />
					</linearGradient>
					<linearGradient id="e" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#34993d" />
						<stop offset="1" stop-color="#52b361" />
					</linearGradient>
					<linearGradient id="f" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#2cb5c0" />
						<stop offset="1" stop-color="#46c8d0" />
					</linearGradient>
					<linearGradient id="g" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#40b945" />
						<stop offset="1" stop-color="#68cc73" />
					</linearGradient>
					<linearGradient id="h" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#2597a8" />
						<stop offset="1" stop-color="#3db2bf" />
					</linearGradient>
				</defs>
				<path
					data-name="Path 3989"
					d="m19.057 28.016-9.042-5.425V.886l9.043 5.426 9.04-5.426v10.851L19.06 6.314l9.038 5.423v10.851l-9.038-5.425 9.038 5.425-9.038 5.425Zm0-10.855-9.042-5.425Zm-18.085 0 9.042 5.427-9.042 5.427Zm0 0V6.312l9.042 5.424v10.852Zm0-10.851L10.014.883v10.854Z"
					fill="#0fa4b3"
					stroke="#3498db"
					stroke-miterlimit="10" />
				<g data-name="Group 5401">
					<path
						data-name="Path 3991"
						d="M48.981 17.032 38.9 1.554v-.036h2.327l8.754 13.594 8.792-13.594h2.328v.036L50.973 17.032v10.343h-2Zm34.62-15.514v16.068c0 13.519-19.8 13.556-19.8 0V1.518h1.957v16.068c0 11.118 15.881 11.081 15.881 0V1.518Zm5.4 0 17.4 21.387V1.518h1.958v25.857h-.775L90.149 6.029v21.346h-1.995V1.518Zm49.543 12.965c-.039 6.8-4.36 13.331-13.039 13.331s-13.038-6.574-13.038-13.408c0-6.945 4.322-13.3 13.038-13.3s13.078 6.397 13.04 13.377Zm-24.117-.04c.036 5.837 3.73 11.525 11.081 11.525 7.388 0 11.12-5.688 11.12-11.525 0-5.984-3.694-11.488-11.12-11.488s-11.119 5.396-11.081 11.488Z"
						fill="#9ca3af" />
				</g>
				<path
					data-name="Path 3993"
					d="m927.042 314.375 9.044-5.426V319.8Z"
					transform="translate(-926.073 -308.063)"
					fill="url(#a)" />
				<path
					data-name="Path 3994"
					d="m927.042 336.816 9.044-5.426v10.852Z"
					transform="translate(-926.073 -319.651)"
					fill="url(#b)" />
				<path
					data-name="Path 3995"
					d="m936.086 325.6-9.044-5.426v10.852Z"
					transform="translate(-926.073 -313.857)"
					fill="url(#c)" />
				<path
					data-name="Path 3996"
					d="m936.086 348.036-9.044-5.426v10.852Z"
					transform="translate(-926.073 -325.446)"
					fill="url(#d)" />
				<path data-name="Path 3997" d="M19.056 6.312 28.099.886v10.852Z" fill="#14a5b4" />
				<path data-name="Path 3998" d="m19.056 17.165 9.043-5.426v10.854Z" fill="#4bc35a" />
				<path data-name="Path 3999" d="m28.099 11.738-9.043-5.426v10.852Z" fill="#1ebec8" />
				<path data-name="Path 4000" d="m28.099 22.588-9.043-5.426v10.854Z" fill="#2da541" />
				<path
					data-name="Path 4001"
					d="m945.743 348.036 9.044 5.426V342.61Z"
					transform="translate(-935.731 -325.446)"
					fill="url(#e)" />
				<path
					data-name="Path 4002"
					d="m945.743 325.6 9.044 5.426v-10.857Z"
					transform="translate(-935.731 -313.857)"
					fill="url(#f)" />
				<path
					data-name="Path 4003"
					d="m954.787 336.816-9.044 5.426V331.39Z"
					transform="translate(-935.731 -319.651)"
					fill="url(#g)" />
				<path
					data-name="Path 4004"
					d="m954.787 314.375-9.044 5.426v-10.852Z"
					transform="translate(-935.731 -308.063)"
					fill="url(#h)" />
			</svg>
			<ng-content select="[version]"></ng-content>
		</div>
	</div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'yuno-project-atlas-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
	private _url: string | undefined;
	absolute: boolean;

	link: string[];

	private _fillColor = 'currentColor';
	@Input() set fillColor(color: string | undefined) {
		this._fillColor = color || 'currentColor';
	}
	get fillColor(): string {
		return this._fillColor;
	}

	@Input() @HostBinding('style.color') color: string;
	@Input()
	set url(url: string | undefined) {
		if (!url) {
			this._url = undefined;
			return;
		}

		this._url = url;

		if (this.absoluteUrl(url)) {
			this.absolute = true;
			return;
		}

		this.absolute = false;
		this.link = url.split('/');
	}
	get url(): string {
		return this._url as string;
	}

	@Input() set page(page: string) {
		this.url = `page/${page}`;
	}

	/* Check if absolute url or application url */
	absoluteUrl(str: string): boolean {
		const absolute = new RegExp('^(?:[a-z]+:)?//', 'i');
		return absolute.test(str);
	}
}

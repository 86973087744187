import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularPipesModule } from '@yuno/angular/pipes';

import { ClusterComponent } from './cluster/cluster.component';
import { DefaultComponent } from './default/default.component';
import { IconComponent } from './icon/icon.component';
import { LabelComponent } from './label/label.component';
import { MarkerCrosshairComponent } from './marker-crosshair/marker-crosshair.component';
import { PanoramaComponent } from './panorama/panorama.component';
import { PhotosComponent } from './photos/photos.component';
import { YunoMarkerComponent } from './yuno-marker/yuno-marker.component';

@NgModule({
	declarations: [
		YunoMarkerComponent,
		LabelComponent,
		IconComponent,
		DefaultComponent,
		ClusterComponent,
		PanoramaComponent,
		PhotosComponent
	],
	imports: [CommonModule, AngularPipesModule, MarkerCrosshairComponent],
	exports: [YunoMarkerComponent, LabelComponent, IconComponent, ClusterComponent]
})
export class MarkersModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SideOptionsMenuComponent } from '@yuno/admin/ui';

import { FileLibraryComponent } from '../file-library/file-library.component';


@Component({
	selector: 'yuno-file-library-routing',
	standalone: true,
	imports: [FileLibraryComponent, SideOptionsMenuComponent],
	templateUrl: './file-library-routing.component.html',
	styleUrls: ['./file-library-routing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryRoutingComponent {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as mapUiButtonsEffects from './+state/map-ui-buttons.effects';
import { MapUiButtonsFacade, mapUiButtonsFeature } from './+state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(mapUiButtonsFeature),
		provideEffects([mapUiButtonsEffects]),
		MapUiButtonsFacade
	]
})
export class MapUiDataAccessModule {}

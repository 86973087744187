@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="1" [span]="4">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2 xl:grid-cols-3">
			@if (service.form) {
				<form [formGroup]="service.form" edit-container-content>
					<div class="flex flex-col gap-8">
						<yuno-forms-text
							formControlName="id"
							label="Dataset name"
							placeholder="dataset name"></yuno-forms-text>
						<ng-container formGroupName="data">
							<ng-container formGroupName="legend">
								<yuno-forms-select
									formControlName="_id"
									class="flex-1"
									label="Legend"
									placeholder="none"
									[selectValues]="service.legendValues"
									[display]="service.legendDisplay">
								</yuno-forms-select>
							</ng-container>
						</ng-container>
						<!-- Data -->
						<div class="grid grid-cols-2 gap-2">
							@for (list of listData; track list; let i = $index) {
								<yuno-card-draggable
									class="hover:cursor-pointer"
									[fullHeight]="true"
									[header]="false"
									[draggable]="false"
									[routerLink]="list.key">
									<ng-container popup>
										<span class="text-sm capitalize">{{ list.display }}</span>
									</ng-container>
									<ng-container content>
										<section class="select-none">
											<h4 class="truncate">{{ list.display }}</h4>
											<span class="text-sm italic">
												{{ onListLength(list.key) }}
												{{ onListLength(list.key) === 1 ? 'is' : 'are' }}
												active
											</span>
										</section>
									</ng-container>
								</yuno-card-draggable>
							}
						</div>
						<div class="flex flex-col gap-4" formGroupName="states">
							<h2>States</h2>
							@for (item of service.states.controls; track item; let i = $index) {
								<div
									class="flex w-full gap-2 rounded border border-gray-300 bg-gray-200 p-1"
									[formArrayName]="i">
									<ng-container formGroupName="state">
										<yuno-forms-select
											formControlName="_id"
											class="flex-1 items-center"
											[selectValues]="service.stateValues"
											[display]="service.stateDisplay">
										</yuno-forms-select>
									</ng-container>
									<div class="flex items-center gap-2">
										<button
											yuno-admin-button
											color="primary"
											[routerLink]="[i, 'state']">
											Styling
										</button>
										<button
											yuno-admin-delete-admin-button
											(click)="removeState(i)"></button>
									</div>
								</div>
							}
							<button yuno-admin-add-button (click)="addState()">Add State</button>
						</div>
					</div>
				</form>
			}
			<div edit-container-content class="xl:col-span-2">
				<router-outlet></router-outlet>
			</div>
		</div>
	</yuno-edit-container>
}

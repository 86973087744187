<div class="relative grid grid-rows-1">
	<yuno-validator-messages [control]="control" [top]="true">
		<ng-content select="[arrayErrors]"></ng-content>
	</yuno-validator-messages>

	<!-- z-60 is needed for correctly displaying errors in child form components -->
	<div class="z-60">
		<ng-content select="[arrayBody]"></ng-content>
	</div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';

import { SpritesheetsService } from '@yuno/admin/features/spritesheets/feature/internal-view/spritesheets.service';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { MessageService, ToastItem, ToastType } from '@yuno/angular/notifications';

@Component({
	selector: 'yuno-admin-sprite-editor',
	templateUrl: './sprite-editor.component.html',
	styleUrls: ['./sprite-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpriteEditorComponent extends AppDataComponent {
	readonly service = inject(SpritesheetsService);
	private readonly message = inject(MessageService);
	private readonly cdr = inject(ChangeDetectorRef);

	success = false;

	async onSave() {
		let url = ``;
		if (this.app && this.client && this.clientId) {
			url = `${this.clientId}`;
		}

		if (this.app && this.appId) {
			url = `${this.clientId}/${this.appId}`;
		}
		try {
			await this.service.postFile(`icon/${url}`, this.service.file);
			this.sendToast(`SVG successfully added/updated!`, 'success');
			this.success = true;
			this.cdr.markForCheck();
		} catch {
			this.sendToast(`Error uploading SVG!`, 'error');
		}
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.success = false;
	}

	sendToast(message: string, type: ToastType) {
		const item: ToastItem = {
			message: message
		};
		this.message.showToast(item, type);
	}
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, tap } from 'rxjs';

import { StatesFacade } from '@yuno/admin/features/states';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminAddButtonComponent,
	YunoAdminButtonRoundComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminDeleteButtonComponent
} from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { DatasetEditorService, PanoramaDataStatesForm } from '../dataset-editor.service';

@Component({
	selector: 'yuno-admin-custom-states-editor',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		EditContainerComponent,
		YunoAdminAddButtonComponent,
		YunoAdminCloseButtonComponent,
		YunoAdminDeleteButtonComponent,
		AsyncPipe,
		YunoAdminButtonRoundComponent,
		YunoAdminDeleteAdminButtonComponent,
		EditContainerContentDirective
	],
	templateUrl: './custom-states-editor.component.html',
	styleUrls: ['./custom-states-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomStatesEditorComponent {
	private readonly stateFacade = inject(StatesFacade);
	readonly service = inject(DatasetEditorService);

	data$ = combineLatest({
		states: this.stateFacade.states$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.stateDisplay = data.map(state => state.state) as string[];
					this.service.stateValues = data.map(state => state._id) as string[];
				}
			})
		)
	});

	get dataPanoramaStates(): FormArray<FormGroup<PanoramaDataStatesForm>> | undefined {
		return this.service.eventIndex >= 0
			? (this.service.dataPanoramas.controls[this.service.eventIndex].get(
					'states'
				) as FormArray<FormGroup<PanoramaDataStatesForm>>)
			: undefined;
	}

	removeCustomState(index: number): void {
		const form = this.service.dataPanoramas.controls[this.service.eventIndex].get(
			'states'
		) as FormArray<FormGroup<PanoramaDataStatesForm>>;

		if (!form) {
			return;
		}

		form.removeAt(index);
	}
}

import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { privacyCustomFeature } from '@yuno/admin/features/privacy-and-cookies-editor/data-access/+state/privacy-statement-projects/privacy-statement.state';
import { PrivacyStatementProject } from '@yuno/api/interface';

import { privacyStatementCustomActions } from './privacy-statement.actions';

@Injectable()
export class PrivacyProjectFacade {
	private readonly store = inject(Store);

	appId$ = this.store.pipe(select(privacyCustomFeature.appId));
	language$ = this.store.pipe(select(privacyCustomFeature.appLanguage));
	privacyUrl$ = this.store.pipe(select(privacyCustomFeature.privacyUrl));

	values$ = this.store.pipe(select(privacyCustomFeature.selectValues));
	config$ = this.store.pipe(select(privacyCustomFeature.selectConfig));
	selected$ = this.store.pipe(select(privacyCustomFeature.selectSelected));

	get(): void {
		this.store.dispatch(privacyStatementCustomActions.values());
	}

	getConfig(): void {
		this.store.dispatch(privacyStatementCustomActions.config());
	}

	clear(): void {
		this.store.dispatch(privacyStatementCustomActions.clear());
	}

	clearSelected(): void {
		this.store.dispatch(privacyStatementCustomActions.clearSelected());
	}

	select(id: string): void {
		this.store.dispatch(privacyStatementCustomActions.value({ id }));
	}

	patch(data: Partial<PrivacyStatementProject>): void {
		this.store.dispatch(privacyStatementCustomActions.patchValue({ data }));
	}

	patchConfig(data: Partial<PrivacyStatementProject>): void {
		this.store.dispatch(privacyStatementCustomActions.patchConfig({ data }));
	}

	save(): void {
		this.store.dispatch(privacyStatementCustomActions.saveValue());
	}

	saveConfig(): void {
		this.store.dispatch(privacyStatementCustomActions.saveConfig());
	}
}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { UsersFacade } from '@yuno/admin/features/users';

export const AppUserResolver: ResolveFn<boolean> = (): Observable<boolean> => {
	const facade = inject(AppFacade);
	const users = inject(UsersFacade);

	facade.getAppUsers();
	users.getUsers();

	return of(true);
};

export const AppUserClientResolver: ResolveFn<boolean> = (): Observable<boolean> => {
	const facade = inject(AppFacade);
	facade.getAppUsersClient();
	return of(true);
};

import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { API_CDN_URL_KEY, API_URL_KEY, ApiService } from './services/api.service';

@NgModule({
	imports: [CommonModule, MatDialogModule, MatButtonModule, MatProgressBarModule],
	providers: [ApiService, provideHttpClient(withInterceptorsFromDi())]
})
export class ApiModule {
	public static forRoot(options: {
		url: string;
		cdn_url?: string;
	}): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [
				{
					provide: API_URL_KEY,
					useValue: options?.url || 'api'
				},
				{
					provide: API_CDN_URL_KEY,
					useValue: options?.cdn_url || 'api'
				}
			]
		};
	}
}

@if (data$ | async; as data) {
	@if (data.events && eventType && dataLayersEvents) {
		<yuno-admin-events-forms
			[arrayName]="eventType"
			[array]="eventArray"
			[group]="dataLayersEvents">
		</yuno-admin-events-forms>
	}
	<h1 class="font-bold">Layers</h1>
	<yuno-admin-table
		[pagination]="true"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [25, 50, 100],
			hidePageSize: false
		}"
		[selectable]="true"
		[filterable]="true"
		[data]="data.layers"
		[selectData]="data.changes"
		[columns]="tableColumns"
		(selected)="onSelect($event)"
		(selectAll)="onSelectAll($event)"
		[buttons]="tableButtons"
		[preMobileButtons]="tablePreButtons"
		[buttonEllipsis]="true"
		(clicked)="onClickEventButton($event, 'onClick')">
		<ng-container searchButtonsLeft>
			@if (copyEventData) {
				<button
					yuno-admin-button
					color="secondary"
					[disabled]="!copyEventData"
					(click)="clearClipboard()">
					Clear clipboard
				</button>
			}
		</ng-container>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	@if (eventButtonsData(row); as eventData) {
		<button
			yuno-admin-button-table
			color="secondary"
			(click)="onClickEventButton(row, 'onClick')">
			Click ({{ eventData.clickCount }})
		</button>
		<button
			yuno-admin-button-table
			color="secondary"
			(click)="onClickEventButton(row, 'onMouseMove')">
			Hover ({{ eventData.hoverCount }})
		</button>
		<button
			yuno-admin-button-table
			color="secondary"
			(click)="onClickEventButton(row, 'onMouseLeave')">
			Leave ({{ eventData.leaveCount }})
		</button>
		<button yuno-admin-button-table color="secondary" (click)="copyEvents(row)">
			Copy Events
		</button>
		@if (copyEventData) {
			<button yuno-admin-button-table color="secondary" (click)="pasteEvents(row)">
				Paste Events
			</button>
		}
	}
</ng-template>
<ng-template #tablePreButtons let-row>
	@if (eventButtonsData(row); as eventData) {
		{{
			eventData.clickCount + eventData.hoverCount + eventData.leaveCount
				? eventData.clickCount + eventData.hoverCount + eventData.leaveCount
				: undefined
		}}
	}
</ng-template>

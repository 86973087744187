import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const legendAnimationTime = 500;
@Injectable({
	providedIn: 'root'
})
export class LegendService {
	private isOpen = new BehaviorSubject(false);
	isOpen$ = this.isOpen.asObservable();

	private closeSlow = new BehaviorSubject(false);
	closeSlow$ = this.closeSlow.asObservable();

	/**
	 * Opens the Legend
	 */
	open(): void {
		this.isOpen.next(true);
	}

	/**
	 * Closes the legend
	 */
	close(): void {
		this.isOpen.next(false);
		this.disableCloseSlowly();
	}

	/**
	 * This is uused by the Container element,
	 * to calculate the closing animation
	 */
	closeSlowly(): void {
		this.closeSlow.next(true);
	}

	/**
	 * resets the closeSlowly element
	 */
	disableCloseSlowly(): void {
		this.closeSlow.next(false);
	}
}

@if (data$ | async; as data) {
	<h1 class="font-bold">GeoPhotos</h1>
	<yuno-admin-table
		[pagination]="true"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [25, 50, 100],
			hidePageSize: false
		}"
		[selectable]="true"
		[filterable]="true"
		[data]="data.geoPhotos"
		[selectData]="data.changes"
		[columns]="tableColumns"
		(selected)="onSelect($event)"
		(selectAll)="onSelectAll($event)">
	</yuno-admin-table>
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LanguagePipe } from '../../../../../pipes/src/lib/language/language.pipe';
import { SafeHtmlIframePipe } from '../../../../../pipes/src/lib/safe-html/safe-html-iframe.pipe';
import { TextBlockComponent } from './../text-block/text-block.component';

@Component({
	selector: 'yuno-textfield-collapsible',
	templateUrl: './../text-block/text-block.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SafeHtmlIframePipe, LanguagePipe]
})
export class TextCollapsibleComponent extends TextBlockComponent {}

<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="collapseElement"
	[cdkConnectedOverlayOpen]="collapseContainerActive">
	<div
		class="absolute left-0 flex flex-col gap-4 rounded-md bg-yuno-gray-500 p-2 text-yuno-gray-200 shadow-md"
		[ngStyle]="{ 'bottom.px': buttonGroupCollapseContainerBottom }">
		<yuno-atlas-button-group-item
			*ngFor="let option of options"
			(click)="optionClicked($event, option, true)"
			[style]="style"
			[activeColor]="value === option.id ? activeColor : undefined"
			[attr.data-id]="option.id">
			{{ option.display | languageSelect: language }}
		</yuno-atlas-button-group-item>
	</div>
</ng-template>

<div #container class="container-border yuno-button-group h-10 overflow-hidden md:h-12 lg:h-14">
	<div class="relative flex h-full flex-row items-center gap-1 bg-yuno-gray-500 p-1">
		<yuno-atlas-button-group-item
			*ngIf="collapse && activeValue as option"
			#collapseElement="cdkOverlayOrigin"
			cdkOverlayOrigin
			class="max-w-full"
			(resized)="resizeCollapsed()"
			(click)="toggleCollapseContainer()"
			[style]="style"
			[activeColor]="value === option.id ? activeColor : undefined"
			[attr.data-id]="option.id">
			<span class="pointer-events-none truncate">
				{{ option.display | languageSelect: language }}
			</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="3"
				stroke="currentColor"
				class="-mr-1 ml-2 h-4 w-4">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="m4.5 15.75 7.5-7.5 7.5 7.5" />
			</svg>
		</yuno-atlas-button-group-item>

		<ng-container *ngIf="!collapse">
			<yuno-atlas-button-group-item
				*ngFor="let option of options; trackBy: trackBy"
				#buttonGroupItems
				(click)="optionClicked($event, option)"
				[style]="style"
				[activeColor]="value === option.id ? activeColor : undefined"
				[attr.data-id]="option.id">
				<span class="pointer-events-none overflow-hidden text-ellipsis whitespace-nowrap">
					{{ option.display | languageSelect: language }}
				</span>
			</yuno-atlas-button-group-item>
		</ng-container>

		<yuno-atlas-button-group-slider
			[style]="style"
			[transition]="transition"
			[collapsed]="collapse"
			[targetSize]="activeElementSizing"
			[color]="activeBackgroundColor"></yuno-atlas-button-group-slider>
	</div>
</div>

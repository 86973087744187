import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

import { FileUploadService } from '@yuno/admin/features/file-upload';
import { AnnotationComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { DialogType, FileFormatTypes } from '@yuno/angular/api';
import { YunoFormsModule } from '@yuno/angular/forms';

import { InternalViewService } from '../../internal-view/internal-view.service';

export interface DialogData {
	[key: string]: unknown;
}

@Component({
	selector: 'yuno-admin-app-configuration-upload-dialog',
	standalone: true,
	imports: [CommonModule],
	template: `
		<div class="flex flex-col gap-4 p-4">
			I close on background click
			<code>
				<pre class="text-xs">{{ data | json }}</pre>
			</code>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				max-height: 80vh;
				overflow: auto;
			}
		`
	]
})
class AppConfigurationUploadDialogComponent {
	constructor(@Inject(DIALOG_DATA) public data: DialogData) {}
}

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		MatDialogModule,
		AnnotationComponent
	],
	selector: 'yuno-admin-app-config-atlas-settings',
	templateUrl: './atlas-settings.component.html',

	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfigurationAtlasSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
	private readonly fileUploadService = inject(FileUploadService);
	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<AppConfigurationUploadDialogComponent>;

	/* Upload YUNO */
	openFileUpload(): void {
		const data: DialogType = {
			appId: this.appId,
			type: FileFormatTypes.yuno,
			filesizeLimit: 50
		};

		this.fileUploadService.openDialog(
			data,
			`${this.environment['yuno-tools-url']}/uploader/upload_datetime/${this.appId}`
		);

		this.fileUploadService.response.pipe(take(1)).subscribe(event => {
			this.openDialogRef(event);
		});
	}

	openDialogRef(event: HttpResponse<never>) {
		this.dialogRef = this.dialog.open(AppConfigurationUploadDialogComponent, {
			data: event.body
		});
	}

	getMatomoUrl(): string {
		const trackingId = this.service.trackingId.value;
		return `${this.environment['yuno-matomo-token']}/index.php?module=CoreHome&action=index&date=yesterday&period=day&idSite=${trackingId}`;
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as statesEffects from './+state/states.effects';
import { StatesFacade } from './+state/states.facade';
import { statesFeature } from './+state/states.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(statesFeature), provideEffects([statesEffects]), StatesFacade]
})
export class StatesDataAccessModule {}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Observable, catchError, filter, of, switchMap, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { NavbarFacade } from '@yuno/admin/features/navbar';
import { StatusPrivate as Status } from '@yuno/admin/utils';

export const AppGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
	const appFacade = inject(AppFacade);
	const navbarFacade = inject(NavbarFacade);

	return appFacade.appState$.pipe(
		tap(state => {
			if (!state.app && ![Status.VALIDATING].includes(state.status)) {
				appFacade.validateApp(route.params['appId']);
			}
		}),
		// Wait for a status that s not pending validating
		filter(state => ![Status.PENDING, Status.VALIDATING].includes(state.status)),
		switchMap(state => {
			if (state.status === Status.FAILED) {
				return of(false);
			}

			state.app?._id &&
				navbarFacade.addRoute({
					route: [state.app._id, 'dashboard'],
					display: state.config?.title || state.app?.id || 'No Name',
					index: 1
				});

			return of(true);
		}),
		catchError(() => {
			return of(false);
		}),
		// the resolver needs an completed Observable and the NGRX observable never gets completed
		// take(1) completes it
		take(1)
	);
};

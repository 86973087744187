@if (data$ | async; as data) {
	<yuno-edit-container [col]="6" [pos]="2" [span]="4">
		<ng-container actions>
			<button yuno-admin-add-button (click)="service.addCustomState()">Custom State</button>
		</ng-container>
		<ng-container buttons>
			<button yuno-admin-close-button (click)="service.customStates$.next(false)"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.dataPanoramas && service.eventIndex >= 0) {
				<form [formGroup]="service.dataPanoramas.controls[service.eventIndex]">
					<yuno-forms-title>Custom States</yuno-forms-title>
					<yuno-forms-span class="my-2"></yuno-forms-span>
					<div formArrayName="states" class="my-2 grid grid-cols-1 gap-1">
						@for (alias of dataPanoramaStates?.controls; track alias; let i = $index) {
							<div
								[formGroupName]="i"
								class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-400 bg-gray-200 p-2">
								<ng-container formGroupName="state">
									<yuno-forms-select
										formControlName="_id"
										class="flex-1"
										placeholder="Choose State..."
										[selectValues]="service.stateValues"
										[display]="service.stateDisplay"></yuno-forms-select>
									<div class="flex justify-center">
										<button
											yuno-admin-delete-admin-button
											(click)="removeCustomState(i)"></button>
									</div>
								</ng-container>
							</div>
						}
					</div>
				</form>
			}
		</div>
	</yuno-edit-container>
}

/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	Renderer2,
	ViewChild
} from '@angular/core';

import { RippleDirectiveModule } from '../ripple/ripple.directive';

@Component({
	selector:
		'yuno-button, button[yuno-button], button[yuno-button-small], button[yuno-button-big]',
	standalone: true,
	imports: [CommonModule, RippleDirectiveModule],
	template: `
		<span
			#buttonContent
			class="yuno-button__content"
			[ngStyle]="{
				backgroundColor: backgroundColor
			}"
			[ngClass]="{
				'!p-0': disablePadding || iconButton,
				'pointer-events-none': disableMouseEvent,
				'hover-active': !disableMouseEvent,
				'!h-auto': autoHeight
			}">
			<span
				class="pointer-events-none z-10"
				[ngClass]="{ 'flex flex-row items-center justify-center': iconButton }">
				<ng-content></ng-content>
			</span>
			<span yuno-ripple-standalone [yunoRippleColor]="hoverColor" class="z-0"></span>
		</span>
	`,
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoButtonComponent {
	@Input() @HostBinding('style.color') color?: string;
	@Input() backgroundColor?: string;
	@Input() disablePadding = false;
	@Input() iconButton = false;

	@Input() @HostBinding('class.pointer-events-none') disableMouseEvent = false;
	@Input() @HostBinding('style.borderColor') borderColor: string;

	/**
	 * Best to use a transparent color as hover
	 * This way the Ripple effect wil still be visible
	 */
	@Input() hoverColor: string;

	@HostBinding('class.yuno-button--big')
	readonly isBig: boolean = this._hasHostAttributes('yuno-button-big');

	@HostBinding('class.yuno-button--small')
	readonly isSmall: boolean = this._hasHostAttributes('yuno-button-small');

	@HostBinding('class.yuno-button--square')
	readonly isSquare: boolean = this._hasHostAttributes('square');

	@HostBinding('class.yuno-button--rounded')
	readonly isRounded: boolean = this._hasHostAttributes('rounded');

	@HostBinding('class.yuno-button--flat-top')
	readonly flatTop: boolean = this._hasHostAttributes('flat-top');

	@HostBinding('class.yuno-button--flat-bottom')
	readonly flatBottom: boolean = this._hasHostAttributes('flat-bottom');

	@HostBinding('class.yuno-button--flat-left')
	readonly flatLeft: boolean = this._hasHostAttributes('flat-left');

	@HostBinding('class.yuno-button--flat-right')
	readonly flatRight: boolean = this._hasHostAttributes('flat-right');

	@ViewChild('buttonContent', { read: ElementRef }) buttonContent: ElementRef;

	readonly autoHeight: boolean = this._hasHostAttributes('h-auto');

	@HostListener('mouseover', ['$event'])
	setMouseOver() {
		if (!this.hoverColor) {
			return;
		}

		this.renderer.setStyle(
			this.buttonContent.nativeElement,
			'backgroundColor',
			this.hoverColor || undefined
		);
	}

	@HostListener('mouseout', ['$event'])
	removeMouseOver() {
		if (!this.hoverColor) {
			return;
		}

		this.renderer.setStyle(
			this.buttonContent.nativeElement,
			'backgroundColor',
			this.backgroundColor || undefined
		);
	}

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef
	) {}

	/** Gets whether the button has one of the given attributes. */
	_hasHostAttributes(...attributes: string[]) {
		return attributes.some(attribute => this.elementRef.nativeElement.hasAttribute(attribute));
	}
}

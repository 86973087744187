<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-4">
	<ng-container
		>Set the language for Project Atlas. You can add additional languages using the button
		below. The language at the top of the list will be the default language. When you add more
		than one language, a pop-up will appear add the top of every screen in this portal that
		contains text fields requiring input for the added languages.
	</ng-container>
	<yuno-forms-array-container
		[control]="service.languages"
		formArrayName="languages"
		cdkDropList
		(cdkDropListDropped)="drop($event)">
		<div arrayBody class="grid grid-cols-1 gap-2">
			@for (item of service.languages.value; track item; let i = $index) {
				<yuno-admin-drag-row cdkDrag cdkDragLockAxis="y" [light]="true">
					<ng-container title>
						<div class="flex items-center justify-between">
							<yuno-forms-select
								[formControlName]="i"
								[placeholder]="'Choose a language'"
								[display]="service.languageDisplay"
								[selectValues]="service.languageSelect">
							</yuno-forms-select>
							<button
								yuno-admin-delete-admin-button
								(click)="service.removeLanguage(i)"></button>
						</div>
					</ng-container>
				</yuno-admin-drag-row>
			}
		</div>
	</yuno-forms-array-container>
	<div class="flex items-center justify-end">
		<button yuno-admin-add-button (click)="service.addLanguage()">
			Add additional language
		</button>
	</div>
</form>

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export interface SliderElementSize {
	offsetTop?: number;
	offsetLeft?: number;
	clientWidth?: number;
	clientHeight?: number;
}

@Component({
	selector: 'yuno-atlas-button-group-slider',
	standalone: true,
	template: '',
	styleUrls: ['./button-group-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupSliderComponent {
	@Input() @HostBinding('class') style: 'square' | 'rounded' | 'round' = 'round';
	@Input() @HostBinding('style.backgroundColor') color: string | undefined;
	@Input() @HostBinding('class.collapsed') collapsed = false;
	@Input() @HostBinding('class.transition') transition = false;

	@HostBinding('style.top.px') private styleTop: number | undefined;
	@HostBinding('style.left.px') private styleLeft: number | undefined;
	@HostBinding('style.width.px') private styleWidth: number | undefined;
	@HostBinding('style.height.px') private styleHeight: number | undefined;

	targetElement: SliderElementSize = {
		offsetTop: 0,
		offsetLeft: 0,
		clientWidth: 0,
		clientHeight: 0
	};

	@Input()
	set targetSize(ele: SliderElementSize) {
		this.targetElement = ele;
		this.setElementSize();
	}

	setElementSize(): void {
		this.styleTop = this.targetElement.offsetTop;
		this.styleLeft = this.targetElement.offsetLeft;
		this.styleWidth = this.targetElement.clientWidth;
		this.styleHeight = this.targetElement.clientHeight;
	}
}

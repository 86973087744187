import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toRadians',
	standalone: true
})
export class ToRadiansPipe implements PipeTransform {
	transform(value: number): number {
		return value * (Math.PI / 180);
	}
}

import { animate, group, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { LayerSpecification, Map, Style } from 'maplibre-gl';
import { combineLatest } from 'rxjs';

import { YunoDirectivesModule } from '@yuno/angular/directives';
import { Legend } from '@yuno/api/interface';
import { isMobile } from '@yuno/libs/shared/helpers';

import { YunoButtonModule } from '../button';
import { LegendContainerComponent } from './legend-container/legend-container.component';
import { LegendService, legendAnimationTime } from './legend.service';

@Component({
	selector: 'yuno-legend',
	standalone: true,
	imports: [CommonModule, YunoButtonModule, LegendContainerComponent, YunoDirectivesModule],
	providers: [LegendService],
	templateUrl: './legend.component.html',
	styleUrls: ['./legend.component.scss'],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				group([
					animate(
						`${legendAnimationTime}ms cubic-bezier(.35,0,.25,1)`,
						style({ opacity: 1 })
					)
				])
			]),
			transition(':leave', [
				style({
					opacity: 1
				}),
				group([animate(`${legendAnimationTime}ms linear`, style({ opacity: 0 }))])
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendComponent implements AfterViewInit {
	private readonly service = inject(LegendService);
	private readonly cdr = inject(ChangeDetectorRef);

	@ViewChild('templatePortalContent') templatePortalContent: TemplateRef<unknown>;
	@ViewChild('contentWrapper', { read: ElementRef }) contentWrapper: ElementRef;
	@ViewChild('button', { read: ElementRef }) button: ElementRef;

	@Input() map: Map;
	@Input() mapStyle: Style;
	@Input() mapLayers: LayerSpecification[];
	@Input() layers: string[];

	_maxHeight = 500;
	@Input() set maxHeight(height: number) {
		this._maxHeight = height;
	}
	get maxHeight(): number {
		return this._maxHeight < 250 ? 250 : this._maxHeight;
	}

	_data: Partial<Legend>;
	@Input() set data(data: Partial<Legend>) {
		this._data = data;

		if (!isMobile() && data.openOnStartup) {
			this.service.open();
		}
	}
	get data(): Partial<Legend> {
		return this._data;
	}

	@Input() language: string | undefined = 'nl';

	title: string;
	offsetRight = 0;
	offsetBottom = 0;

	data$ = combineLatest({
		isOpen: this.service.isOpen$,
		isClosing: this.service.closeSlow$
	});

	@HostListener('window:resize', ['$event']) onResize() {
		this.setButtonRect();
	}

	ngAfterViewInit(): void {
		this.setButtonRect();
		this.getTitle();

		const updatePosition = () => {
			this.setButtonRect();
			requestAnimationFrame(updatePosition);
		};

		requestAnimationFrame(updatePosition);
	}

	getTitle(): void {
		this.title = this.contentWrapper?.nativeElement?.innerText;
	}

	setButtonRect(): void {
		const rect = this.button.nativeElement.getBoundingClientRect();
		const offsetRight = window.innerWidth - rect.right;
		const offsetBottom = window.innerHeight - rect.bottom;

		if (offsetRight === this.offsetRight && offsetBottom === this.offsetBottom) {
			return;
		}

		this.offsetRight = offsetRight;
		this.offsetBottom = offsetBottom;

		this.cdr.markForCheck();
	}

	close(): void {
		this.service.close();
	}

	closeSlowly(): void {
		this.service.closeSlowly();
	}

	open(): void {
		this.service.open();
	}
}

<div class="mb-8 flex flex-col gap-8">
	<yuno-card>
		<div content class="grid grid-cols-1 gap-4">
			<div class="mb-6 flex flex-col gap-6">
				<section class="grid grid-cols-2 gap-4">
					<div>
						<h4>XKP Project Manager:</h4>
						{{ lead || 'undisclosed' }}
					</div>
					<div>
						<h4>XKP Visual Engineer:</h4>
						{{ engineer || 'undisclosed' }}
					</div>
					<div>
						<h4>Client Contact:</h4>
						{{ contact || 'undisclosed' }}
					</div>
				</section>
			</div>
		</div>
	</yuno-card>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as customTilesetEffects from './+state/tilesets-custom.effects';
import { TilesetsCustomFacade } from './+state';
import { customTilesetFeature } from './+state/tilesets-custom.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [
		provideState(customTilesetFeature),
		provideEffects([customTilesetEffects]),
		TilesetsCustomFacade
	]
})
export class TilesetsDataAccessModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideState } from '@ngrx/store';

import { NavbarFacade } from './+state/navbar.facade';
import { navbarFeature } from './+state/navbar.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(navbarFeature), NavbarFacade]
})
export class NavbarDataAccessModule {}

import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Config } from '@yuno/api/interface';

import { appConfigActions } from './config.actions';
import { appConfigFeature } from './config.state';

@Injectable()
export class ConfigFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(appConfigFeature.selectLoaded));
	config$ = this.store.pipe(select(appConfigFeature.selectConfig));
	status$ = this.store.pipe(select(appConfigFeature.selectStatus));

	get(appId: string) {
		this.store.dispatch(appConfigActions.load({ appId }));
	}

	update(data: Partial<Config>) {
		this.store.dispatch(appConfigActions.update({ data }));
	}
}
